import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";
import {useState, useEffect, useRef, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSnack} from "../../../utils/useSnack";
import Container from "../../../common/Container";
import FullScreenDialog from "../../../components/Modal/FullScreenDialog";
import { default as Form } from './component'
import { useConfirm } from "material-ui-confirm";
import {reportService} from "../../../api/services/report";
import {
    RatingTotalData,
    ViewData
} from "../../../model/report";
import {DictionaryObject} from "../../../model/dictionary";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ReportFilter from "../../../layouts/components/ReportFilter";
import {mainImage, regionLogoImage} from "./consts";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        title: {
            display: false,
            text: 'Рейтинг качества жизни',
        },
        datalabels: {
            display: true,
            color: 'white',
            font: {
                weight: 600
            }
        }
    },
};

export default function View() {

    const { snack } = useSnack();

    const chartRef = useRef<any>();

    const handleDownload = (type: 'word' | 'pdf') => {

        const canvas = document.getElementById("barChart") as HTMLCanvasElement;
        const image = document.getElementById("barChart--img") as HTMLDivElement
        if(!!image) {
            image.style.backgroundImage = `url('${canvas?.toDataURL()}')`
            image.style.backgroundRepeat = "no-repeat"
            image.style.backgroundSize = "cover"
            image.style.display = 'block'
            image.style.height = canvas.style.height
        }
        try {
            const content = document.getElementById("reportDiv")?.innerHTML || ''
            reportService.downloadReport(year || new Date().getFullYear(), period?.nameRu || '', content, type)
        } finally {
            if(!!image) {
                image.style.display = 'none'
                image.style.backgroundImage = 'none'
            }
        }
    }


    const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
        array.reduce((map, value, index, array) => {
            const key = predicate(value, index, array);
            map.get(key)?.push(value) ?? map.set(key, [value]);
            return map;
        }, new Map<Q, T[]>());

    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [contentTitle, setContentTitle] = useState('')
    const [content, setContent] = useState('')
    const [contentHTML, setContentHTML] = useState('')
    const [year, setYear] = useState<number | null>(null)
    const [period, setPeriod] = useState<DictionaryObject | null>(null)
    const [viewData, setViewData] = useState<ViewData>({ subFactors:[], totals:[], categories: [], tableCategories: [], lastPlaces: [] })
    const [loading, setLoading] = useState(false);
    const [tableCategories, setTableCategories] = useState<PairTable[]>([])
    const [tableLastPlaces, setTableLastPlaces] = useState<any>([])
    const confirm = useConfirm();

    const [labels, setLabels] = useState<string[]>([])
    const [data, setData] = useState<any>({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                borderColor: 'rgb(24, 108, 188)',
                backgroundColor: 'rgba(24, 108, 188, 0.8)',
            }
        ],
    })

    function onSubmit(report: string) {
        console.log(report)
        setOpen(false)
        setContentHTML(report)
    }

    async function search(value: {year: number, period: any, report: any}) {
        setLoading(true)
        try {
            if(!value.report?.id) {
                return
            }
            setYear(value.year)
            setPeriod(value.period)
            setTableCategories([])
            const response = await reportService.getViews(value.report?.id)
            if(response.status === 'success' && response.data) {
                const currentLabels = response.data.totals.map(value => value.regionNameRu);
                setLabels(currentLabels)
                setData({
                    labels: currentLabels,
                    datasets: [
                        {
                            label: '',
                            data: response.data.totals.map(value => value.score),
                            borderColor: 'rgb(24, 108, 188)',
                            backgroundColor: 'rgba(24, 108, 188, 0.8)',
                        }
                    ],
                })
                setViewData(response.data)
                const tableCategories = response.data.tableCategories
                if(!!tableCategories && tableCategories.length > 0) {
                    const keys = Object.keys(tableCategories[0])
                    const arrCount = Math.ceil((keys.length - 1) / 4)
                    let start = 1
                    let end = 5
                    let tempTable: any[] = []
                    let pair: PairTable = {left: null, right: null}
                    for(let i = 0; i < arrCount; i++) {
                        let right = pairTable(start, end, keys, tableCategories)
                        start = end
                        end = end + 4;
                        let left =  pairTable(start, end, keys, tableCategories)
                        start = end
                        end = end + 4;
                        pair = {right, left}
                        i++
                        tempTable.push(pair)
                    }
                    setTableCategories(tempTable)
                }

                setTableLastPlaces(response.data.lastPlaces || [])

                //@ts-ignore
                $('#report-rating-col').uncolumnize()
                setTimeout(() =>
                    //@ts-ignore
                    $('#report-rating-col').columnize({ columns: 2, width: '105mm'}), 100 )


            }
        } catch (e) {
            console.error(e)
            snack(t("snack.error.announcements_registry"), "error");
        } finally {}
    }
    const { t } = useTranslation();

    const pairTable = (start: number, end: number, keys: string[], tableCategories: any[] | null): {columns: Set<string>, rows: Map<string, any[]> } | null=> {
        let labels: string[] = keys.slice(start, end);
        if(labels.length === 0) {
            return null
        }
        let columns: Set<string> = new Set<string>(labels)
        let rows: Map<string, any> = new Map<string, any>()
        for(let category of tableCategories || []) {
            let tempRowArr = []
            for(let key of labels) {
                tempRowArr.push(category[key])
            }
            rows.set(category.name_ru, tempRowArr)
        }
        return {columns, rows}
    }

    const Position = (props: {index: number, total: RatingTotalData}) => {
        switch (props.index) {
            case 0:
                return <PositionOne index={props.index} total={props.total}/>
            case 1:
                return <PositionTwo index={props.index} total={props.total}/>
            case 2:
                return  <PositionThree index={props.index} total={props.total}/>
            case 3:
                return  <PositionFour index={props.index} total={props.total}/>
            case 4:
                return  <PositionFive index={props.index} total={props.total}/>
            case 5:
                return  <PositionSix index={props.index} total={props.total}/>
            case 6:
                return  <PositionSeven index={props.index} total={props.total}/>
            case 7:
                return  <PositionEight index={props.index} total={props.total}/>
            case 8:
                return  <PositionNine index={props.index} total={props.total}/>
            default:
                return  <PositionTen index={props.index} total={props.total}/>
        }
    }

    function convert(value: number | null): string {
        if(!value) {
            return ''
        }
        return `${value}`
    }

    return (

        <Box>
        <Container paddingY={{ xs: 2, sm: 4 }}>
            <Box marginBottom={4}>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                    }}
                >
                   <>{t("pages.view")}</>
                </Typography>
            </Box>

            <ReportFilter onSubmit={search} />

            <Grid item container paddingY={{ xs: 2, sm: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={2} md={2}>
                    <Button fullWidth color="primary" variant="contained"
                            onClick={() => {
                                setTitle(t("pages.admin.dictionary.dialog.title_add"))
                                setOpen(true)
                            }}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.add")}</>
							</span>
                    </Button>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <Button fullWidth color="primary" variant="contained"
                            onClick={() => handleDownload("pdf")}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.downloadPdf")}</>
							</span>
                    </Button>
                </Grid>
                {/*<Grid item xs={2} sm={2} md={2}>*/}
                {/*    <Button fullWidth color="primary" variant="contained"*/}
                {/*            onClick={() => handleDownload("word")}>*/}
				{/*			<span style={{ whiteSpace: 'nowrap' }}>*/}
				{/*				 <>{t("pages.admin.dictionary.buttons.downloadWord")}</>*/}
				{/*			</span>*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>

            <div style={{display:  `${viewData?.totals?.length > 0 ? 'block' : 'none'}`}} id={"reportDiv"}>
                <div id="report-main"
                    style={{
                    fontFamily: 'Times New Roman", Times, serif',
                    position: "relative",
                    height: '370mm',
                    // width: '195mm',
                    // marginLeft: 'auto',
                    // marginRight: 'auto',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: mainImage}}>

                    <img style={{position: "absolute", maxHeight: "250px", top: 30, left: 40}} src={regionLogoImage}/>
                    <p style={{position: "absolute", fontWeight: 600, fontSize: 64, top: 50, right: 50}}>{year}</p>
                    <p style={{position: "absolute", fontWeight: 600, fontSize: 20, top: 180, right: 50}}>{period?.nameRu}</p>
                </div>
                <div id="report-body" style={{
                    display: 'block',
                    clear: 'both',
                    pageBreakAfter: 'always',
                    fontFamily: 'Times New Roman", Times, serif'
                }} dangerouslySetInnerHTML={{ __html: contentHTML }}/>

                    <div id="report-rating" style={{
                        // width: '210mm',
                        display: 'block',
                        clear: 'both',
                        pageBreakAfter: 'always',
                        fontFamily: 'Times New Roman", Times, serif',
                        // marginLeft: 'auto',
                        // marginRight: 'auto'
                    }}>
                        <h1 style={{margin: '80px 40px'}}>Рейтинг в цифрах</h1>
                        <div id={"report-rating-col"} className={"fulljustify"}>
                            <div>
                                <span style={{marginLeft: '40px'}}>Все </span>показатели сгруппированы по {viewData.categories.length} категориям показателей:
                            </div>
                            {
                                viewData.categories.map((category, index) => (
                                    <p key={index}>{index + 1}) {category.nameRu.concat(index === viewData.categories.length - 1 ? '' : ';')}</p>
                                ))
                            }
                            <p><span>&nbsp;</span><br/></p>
                            {
                                viewData.totals.map((total, index) => (
                                    <div key={index}>
                                        <Position index={index} total={total}/>
                                        <p><span>&nbsp;</span><br/></p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div id="report-horizontal-bar" style={{
                        width: '195mm',
                        height: '200mm',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        clear: 'both',
                        pageBreakAfter: 'always',
                        fontFamily: 'Times New Roman", Times, serif',
                        // marginLeft: 'auto',
                        // marginRight: 'auto'
                    }}>
                        <p>Рисунок 1 – Рейтинг районов за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года</p>
                        <div id="barChart--img" style={{display: 'none'}}></div>
                        <Bar ref={chartRef} redraw={true} id="barChart" options={options} data={data} />
                    </div>
                   { tableCategories.map((val: PairTable, index: number) => (
                        <div id="report-table-category"  key={index} style={{
                            // width: '210mm',
                            display: 'block',
                            clear: 'both',
                            pageBreakAfter: 'always',
                            fontFamily: 'Times New Roman", Times, serif',
                            // marginLeft: 'auto',
                            // marginRight: 'auto'
                        }}>

                            { !!val.right &&
                              <>
                                <p>Рисунок {index + 2 + index} – Рейтинг районов за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года в разрезе категорий</p>
                                <Table rows={val.right.rows} label={"Категория"} columns={val.right.columns}/>
                              </>
                            }
                            { !!val.left &&
                                <>
                                    <p>Рисунок {index + 3 + index} – Рейтинг районов за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года в разрезе категорий</p>
                                    <Table rows={val.left.rows} label={"Категория"} columns={val.left.columns}/>
                                </>
                            }
                        </div>
                   ))
                   }
                   {
                        tableLastPlaces.map((val: any, index: number) => (
                            <div id="report-table-last-place"  key={index} style={{
                                // width: '210mm',
                                display: 'block',
                                clear: 'both',
                                pageBreakAfter: tableLastPlaces.length - 1 === index ? 'auto' : 'always',
                                fontFamily: 'Times New Roman", Times, serif',
                                // marginLeft: 'auto',
                                // marginRight: 'auto'
                            }}>
                                <p>Рисунок {index + 2 + (tableCategories.length * 2)} – Низкие показатели рейтинга за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года «{val.katoNameRu}»</p>

                                <TableLastPlace rows={val.lastPlaceIndicators} />
                            </div>
                        ))
                   }
            </div>


            <FullScreenDialog
                open={open}
                setOpen={setOpen}
                form={<Form onSubmit={onSubmit} content={content} setContent={setContent}
                            setContentTitle={setContentTitle} contentTitle={contentTitle}/>}
                title={title}
                t={t}/>
        </Container>
        </Box>
    )
}

const PositionOne = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return (
        <>
        <span style={{marginLeft: '40px'}}>1 </span>
            место в рейтинге качества жизни в районах занял {total.regionNameRu} с результатом {total.score} балла. Район оказался в лидирующей позиции сразу по {total.categories.length} категориям:

            {
                total.categories.map((category, index) => (
                    <>
                     <span key={index}> «{category.categoryNameRu}» </span>  <span key={'1_' + index}>({category.place} место, </span><span key={'2_' + index}>{category.score} балла){index === total.categories.length - 1 ? '.' : ', '}</span>
                    </>
                ))
            }
        </>
    )
}


const PositionTwo = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return (
        <>
            <span style={{marginLeft: '40px'}}>На </span> {index + 1} месте в рейтинге расположился {total.regionNameRu} с {total.score} балла. Район занял лидирующие позиции по категориям
            {
                total.categories.slice(0, 2).map((category, index) => (
                    <>
                        <span key={index}> «{category.categoryNameRu}» &nbsp;</span><span key={'1_' + index}>({category.place} место,&nbsp;</span> <span key={'2_' + index}>{category.score} балла){index === total.categories.length - 1 ? '.' : ', '}</span>
                    </>
                ))
            }
        </>
    )
}

const PositionThree = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return (
        <>
            <span style={{marginLeft: '40px'}}>На </span> {index + 1} месте расположился {total.regionNameRu}. Наилучшие позиции района можно отметить по категории
            { total.categories.length > 0 &&
                <>
                    <span> «{total.categories[0].categoryNameRu}» </span><span>({total.categories[0].place} место,</span> <span>{total.categories[0].score} балла).</span>
                </>
            }
        </>
    )
}

const PositionFour = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return (
        <>
            <span style={{marginLeft: '40px'}}>{index + 1}</span> место занимает {total.regionNameRu} Наилучшие позиции района можно
            { total.categories.length > 0 &&
                <span> отметить по категории  «{total.categories[0].categoryNameRu}».</span>
            }
        </>
    )
}

const PositionFive = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    const length = total.categories.length
    return <>
            <span style={{marginLeft: '40px'}}>На </span>{index + 1} месте расположился {total.regionNameRu}. В текущем рейтинге относительно высокие позиции района
                { length > 0 &&
                    <><span> можно отметить по категории «{total.categories[0].categoryNameRu}» ({total.categories[0].place} место,  {total.categories[0].score} балла){length > 1 ? ' и' : ''}</span>
                        {
                            length > 1 &&
                            <>
                                <span>«{total.categories[1].categoryNameRu}» </span><span>({total.categories[1].place} место, </span><span>{total.categories[1].score} балла).</span>
                            </>
                        }
                    </>
                }
          </>
}

const PositionSix = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return (
        <>
            <span style={{marginLeft: '40px'}}>{index + 1}</span> позицию рейтинга занимает {total.regionNameRu}. В целом район показал значения выше средних по следующим категориям
            {
                total.categories.slice(0, 2).map((category, index) => (
                    <>
                        <span key={index}> «{category.categoryNameRu}» </span><span>({category.place} место, </span><span>{category.score} балла){index === total.categories.length - 1 ? '.' : ', '}</span>
                    </>
                ))
            }
        </>
    )
}

const PositionSeven = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return <>
            <span style={{marginLeft: '40px'}}>{index + 1}</span> позицию занимает {total.regionNameRu}. Наилучшие позиции
            { total.categories.length > 0 &&

                <>
                    <span> «{total.categories[0].categoryNameRu}» - </span><span>{total.categories[0].place} место</span><span> c {total.categories[0].score} балла.</span>
                </>
            }
        </>
}

const PositionEight = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return <>
            <span style={{marginLeft: '40px'}}>{index + 1} </span>позицию в рейтинге занимает {total.regionNameRu}. В текущем рейтинге можно отметить высокие позиции района по категории
            { total.categories.length > 0 &&
                <>
                    <span> «{total.categories[0].categoryNameKz}» - </span><span>{total.categories[0].place} место</span><span>  c {total.categories[0].score} балла.</span>
                </>
            }
        </>
}

const PositionNine = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return <>
            <span style={{marginLeft: '40px'}}>На</span> {index + 1} месте в текущем рейтинге расположился {total.regionNameRu}. Самый высокий показатель у района по категориям
                { total.categories.length > 0 &&
                    <>
                        <span> «{total.categories[0].categoryNameRu}» - </span><span>{total.categories[0].place} место</span><span> c {total.categories[0].score} балла{total.categories.length > 1 ? ', ' : '.'}</span>
                    </>
                }
                { total.categories.length > 1 &&
                    <>
                        <span> По категории «{total.categories[1].categoryNameRu}» </span><span>занял {total.categories[1].place} место</span><span>{total.categories.length > 2 ? ', ' : '.'}</span>
                    </>
                }
                { total.categories.length > 2 &&
                    <>
                        <span> по категории «{total.categories[2].categoryNameRu}» - </span><span>{total.categories[2].place} позиция.</span>
                    </>
                }
        </>
}

const PositionTen = (props: {index: number, total: RatingTotalData}) => {
    const { index, total } = props
    return <>
            <span style={{marginLeft: '40px'}}>На</span> {index + 1} месте расположился  «{total.regionNameRu}». Наилучшие показатели у района сложились
                { total.categories.length > 0 &&
                    <>
                        <span> по категории «{total.categories[0].categoryNameRu}» - </span><span>{total.categories[0].place} позиция </span><span>c {total.categories[0].score} балла.</span>
                    </>
                }
        </>
}

export const Table = (props: {columns: Set<string>, rows: Map<string, any>, label: string}) => {

    const {columns, rows, label} = props
    const cols = [...columns]
    const keys = [...rows.keys()]
    return <>

        <table style={{width: '100%', border: '1px solid white', backgroundColor: '#186cbcd9', color: 'white'}}>
            <thead>
                <tr>
                    <th  style={{border: '1px solid white', padding: '5px', textAlign: 'center', verticalAlign: 'middle', width: "250px"}}>{label}</th>
                {
                    cols.map((col: string, index) => (
                        <th style={{border: '1px solid white', padding: '5px', verticalAlign: 'middle', width: "230px"}} key={index} colSpan={2}>
                            {col}
                        </th>
                    ))
                }
                </tr>
                <tr>
                    <th style={{border: '1px solid white', padding: '5px', textAlign: 'center', verticalAlign: 'middle'}}>Регион</th>
                    {
                        cols.map((col: string, index) => (
                            <>
                                <th style={{border: '1px solid white', padding: '5px'}} key={'score' + index}>
                                    Балл
                                </th>
                                <th style={{border: '1px solid white', padding: '5px'}} key={'place' + index}>
                                    Позиция
                                </th>
                            </>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
            {
                keys.map((key, index) => (
                    <tr>
                        <td style={{border: '1px solid white', textAlign: 'right', padding: '5px'}} key={index}>
                            {key}
                        </td>
                        {
                            rows.get(key).map((row: any, index: number) => (
                                <>
                                    <td style={{border: '1px solid white', padding: '5px'}} key={'score' + index}>{row.score}</td>
                                    <td style={{border: '1px solid white', padding: '5px'}} key={'place' + index}>
                                        <div style={{display: "flex"}}>
                                            {
                                                row.place <= 2 ?  <div className="circle green"></div> : <></>
                                            }
                                            {
                                                row.place > 2 && row.place <= 8 ?  <div className="circle orange"></div> : <></>
                                            }
                                            {
                                                row.place >= 9 ? <div className="circle red"></div> : <></>
                                            }
                                            <span style={{marginRight: "10px"}}></span>
                                            <span>{row.place}</span>
                                        </div>
                                    </td>
                                </>
                            ))
                        }
                    </tr>
                ))
            }
            </tbody>
        </table>
    </>
}


const TableLastPlace = (props: {rows: any[] }) => {
    const { rows } = props
    return (
        <table style={{width: '100%', border: '1px solid black'}}>
            <thead>
            <tr style={{backgroundColor: "#d9c5a0"}}>
                <th  style={{border: '1px solid black', padding: '5px', textAlign: 'center', verticalAlign: 'middle', width: "53px"}}>№</th>
                <th style={{border: '1px solid black', padding: '5px', verticalAlign: 'middle', width: "500px"}}>Наименование</th>
                <th style={{border: '1px solid black', padding: '5px', verticalAlign: 'middle', width: "320px"}}>Ед. изм.</th>
                <th style={{border: '1px solid black', padding: '5px', verticalAlign: 'middle'}}>Значение</th>
            </tr>
            </thead>
            <tbody>
            {
                (rows || []).map((row, index) => (
                    <tr key={`last-table-${index}`}>
                        <td style={{border: '1px solid black',  verticalAlign: 'middle', textAlign: 'center', padding: '5px'}}>
                            {index + 1}
                        </td>
                        <td style={{border: '1px solid black', textAlign: 'left', padding: '5px'}}>
                            {row.indicatorNameRu}
                        </td>
                        <td style={{border: '1px solid black', verticalAlign: 'middle', textAlign: 'center', padding: '5px'}}>
                            {row.unitMeasureNameRu}
                        </td>
                        <td style={{border: '1px solid black', verticalAlign: 'middle', textAlign: 'center', padding: '5px', color: "red"}}>
                            {row.value}
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}

export type PairTable = {
    right: {columns: Set<string>, rows: Map<string, any[]> } | null
    left: {columns: Set<string>, rows: Map<string, any[]> } | null
}
