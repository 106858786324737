import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {DictionaryObject} from "../../../../model/dictionary";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from '@emotion/styled';
import {useEffect, useState} from "react";
import {dictionaryService} from "../../../../api/services/dictionary";
import {ReportData} from "../../../../model/report";

export default function Form({onSubmit, initialValues, readOnly}: {onSubmit: (e: ReportData) => void, initialValues: ReportData,
    readOnly: boolean}) {
    const { t } = useTranslation();
    const validationSchema = yup.object({
        nameKz: yup
            .string()
            .trim()
            .required(t("validation.required")),
        nameRu: yup
            .string()
            .trim()
            .required(t("validation.required")),
        periodId: yup
            .number()
            .nullable()
            .required(t("validation.required"))
            .transform((_, val) => val === Number(val) ? val : null),
        year: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(2000, t("validation.min") + 2000)
            .max(3000, t("validation.max") + 3000)
            .nullable()
            .required(t("validation.required"))
            .transform((_, val) => val === Number(val) ? val : null)

    });
    const [dictPeriods, setDictPeriods] = useState<DictionaryObject[]>([])

    async function getDictPeriods() {
        try {
            const response = await dictionaryService.getDictPeriods()
            if(response.status === 'success' && response.data) {
                setDictPeriods(response.data)
            }
        } catch (ignored) {}
    }
    useEffect(() => {
        getDictPeriods()
    }, [])

    function convert(value: number | null): string {
        if(!value) {
            return ''
        }
        return `${value}`
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });
    return <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        sx={{ paddingRight: { sm: '3rem' } }}
        onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
        }}>

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameKz")} *`}
            name={'nameKz'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameKz}
            onChange={formik.handleChange}
            error={!!formik.touched.nameKz && !!formik.errors.nameKz}
            helperText={formik.touched.nameKz && formik.errors.nameKz}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameRu")} *`}
            name={'nameRu'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameRu}
            onChange={formik.handleChange}
            error={!!formik.touched.nameRu && !!formik.errors.nameRu}
            helperText={formik.touched.nameRu && formik.errors.nameRu}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.year")} *`}
            name={'year'}
            inputMode={'numeric'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.year}
            onChange={(value) => formik.setFieldValue("year", Number(value.target.value), true)}
            error={!!formik.touched.year && !!formik.errors.year}
            helperText={formik.touched.year && formik.errors.year}
        />
        <FormControl>
            <InputLabel id="select-label-period">{`${t("pages.admin.dictionary.selects.period")} *`}</InputLabel>
            <Select
                labelId="select-label-period"
                id="select-period"
                name={"periodId"}
                disabled={readOnly}
                label={`${t("pages.admin.dictionary.selects.period")} *`}
                sx={{ mb: '1.5rem' }}
                value={convert(formik.values.periodId)}
                error={!!formik.touched.periodId && !!formik.errors.periodId}
                onChange={(value) => formik.setFieldValue("periodId", Number(value.target.value), true)}>
                <MenuItem key={''} value={''}>{' '}</MenuItem>
                {
                    dictPeriods.map((value, index) => (
                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                    ))
                }
                </Select>

            <FormHelperText error={!!formik.touched.periodId && !!formik.errors.periodId}>{(formik.errors.periodId && formik.touched.periodId) && formik.errors.periodId}</FormHelperText>
        </FormControl>

        {readOnly ?
            <></>
            : <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <LoadingButton
                    loading={false}
                    type='submit'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                    }}>
                    <>{t("pages.admin.dictionary.buttons.save")}</>
                </LoadingButton>
            </Box>
        }
    </Box>
}


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
