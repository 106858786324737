export const pages = [
    {
        title: 'pages.reports',
        id: 'company-contests',
        pages: [
            {
                title: 'pages.reports',
                href: '/reports',
            },
            {
                title: 'pages.view',
                href: '/view',
            }
        ],
    },
];
