import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useSnack} from "../../../utils/useSnack";
import {DataGrid} from "@mui/x-data-grid";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import Container from "../../../common/Container";
import {Column, columnStyle} from "../../../common/Columns";
import FullScreenDialog from "../../../components/Modal/FullScreenDialog";
import {default as Form} from './component'
import {useConfirm} from "material-ui-confirm";
import {reportService} from "../../../api/services/report";
import {initialValueRating, RatingCommand, RatingData, ReportData} from "../../../model/report";
import {State} from '../../../redux/store';
import {useDispatch, useSelector} from "react-redux";
import {SET_REPORT} from "../../../redux/reducers/report";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {DictionaryObject} from "../../../model/dictionary";
import {dictionaryService} from "../../../api/services/dictionary";
import {useFormik} from "formik";
import * as yup from "yup";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function Rating() {

    const { t } = useTranslation();
    const { snack } = useSnack();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('');

    const state = useSelector((state: State) => state);
    let report: ReportData | null = state.report.report;
    const { reportId } = useParams();
    const [ratings, setRatings] = useState<RatingData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const resetValue: RatingData = initialValueRating
    const [initialValues, setInitialValues] = useState<RatingData>(resetValue)

    const [row, setRow] = useState<RatingData | null>(null)
    const [readOnly, setReadOnly] = useState(false)

    const onPageChange = (newPage: number) => setPage(newPage);
    const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};
    const confirm = useConfirm();
    const dispatch = useDispatch()

    const [expanded, setExpanded] = useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    function onClose() {
        getRatings()
    }
    const handleRemoveClick = (report: RatingData ) => {
        confirm({ description: t("modals.common.remove_confirm") as string, title: '',
            cancellationText: t("modals.common.cancel") as string, confirmationText: t("modals.common.yes") as string })
            .then( async () => {
                try {
                    const response = await reportService.removeRating(report.id!!);
                    if (response.status === "success") {
                        getRatings()
                    }
                } catch (error) {
                    snack(t("snack.error.announcements_registry"), "error");
                } finally {
                    setInitialValues(resetValue)
                    setRow(null)
                }
            })
    };

    async function getRatings(search?: URLSearchParams) {
        try {
            setLoading(true);
            const response = await reportService.getRatings(page, pageSize, reportId || '0', search?.toString());

            if (response.status === "success" && response.data) {
                setRatings(response.data.data);
                setTotalElements(response.data.totalElements);
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {
            setLoading(false);
        }
    }

    const [indicator, setIndicator] = useState<{nameRu: string, nameKz: string, id: number | null} | null>(
        {nameRu: '', nameKz: '', id: null})



    function clean(obj: any): any {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj
    }

    const [dictFactors, setDictFactors] = useState<DictionaryObject[]>([])
    const [dictSubFactors, setDictSubFactors] = useState<DictionaryObject[]>([])
    const [dictCategories, setDictCategories] = useState<DictionaryObject[]>([])
    const [dictSubCategories, setDictSubCategories] = useState<DictionaryObject[]>([])
    const [dictIndicators, setDictIndicators] = useState<DictionaryObject[]>([])


    async function getDictCategories() {
        try {
            const response = await dictionaryService.getDictCategories()
            if(response.status === 'success' && response.data) {
                setDictCategories(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictFactors() {
        try {
            const response = await dictionaryService.getDictFactors()
            if(response.status === 'success' && response.data) {
                setDictFactors(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictIndicators() {
        try {
            const response = await dictionaryService.getDictIndicators()
            if(response.status === 'success' && response.data) {
                setDictIndicators(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictSubCategories(categoryId: number) {
        if(!categoryId) {
            setDictSubCategories([])
            return
        }
        try {
            const response = await dictionaryService.getDictSubCategoriesByCategoryId(categoryId)
            if(response.status === 'success' && response.data) {
                setDictSubCategories(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictSubFactors(factorId: number) {
        if(!factorId) {
            setDictSubFactors([])
            return
        }
        try {
            const response = await dictionaryService.getDictSubFactorsByFactorId(factorId)
            if(response.status === 'success' && response.data) {
                setDictSubFactors(response.data)
            }
        } catch (ignored) {}
    }

    const validationSchema = yup.object({
        categoryId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null),
        subCategoryId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null),
        factorId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null),
        subFactorId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null),
        indicatorId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null)

    })

    const [command, setCommand] = useState(RatingCommand.ADD)

    const iniValue: {categoryId: number | null , subCategoryId: number | null, factorId: number | null, subFactorId: number | null, indicatorId: number | null} = {
        categoryId: null, subCategoryId: null, factorId: null, subFactorId: null, indicatorId: null}

    const formik = useFormik({
        initialValues: iniValue,
        validationSchema: validationSchema,
        onSubmit: onSubmitSearch,
        enableReinitialize: true
    });

    let [searchParams, setSearchParams] = useSearchParams();

    async function onSubmitSearch(e: {categoryId: number | null , subCategoryId: number | null, factorId: number | null, subFactorId: number | null, indicatorId: number | null}) {
        try {
            let params = clean(JSON.parse(JSON.stringify(e)))
            setSearchParams(params, { replace: true });

             getRatings(new URLSearchParams(params))

        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {}
    }


    useEffect(() => {
        if(!report && reportId) {
            reportService.getReport(reportId).then((response) => {
                if (response.status === "success" && response.data) {
                    dispatch({type: SET_REPORT, payload: response.data})
                    report = response.data
                }
            })
        }
        getRatings(searchParams)
        getDictCategories()
        getDictFactors()
        getDictIndicators()
    }, [page, pageSize]);

    function convert(value: number | null): string {
        if(!value) {
            return ''
        }
        return `${value}`
    }

    return (
        <Box>
            <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
                <Box marginBottom={4}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontWeight: 600,
                        }}>
                        <>{t("pages.ratings")}. {report?.year} г. за {report?.periodNameRu}</>
                    </Typography>
                </Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{`${t("pages.panel.filter")}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            display='flex'
                            flexDirection='column'
                            component='form'
                            noValidate
                            autoComplete='off'
                            sx={{ paddingRight: { sm: '3rem' } }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                formik.handleSubmit()
                            }}>
                            <Grid item marginBottom={2} container spacing={2} alignItems={"flex-start"}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label-factor">{`${t("pages.admin.dictionary.selects.factor")} *`}</InputLabel>
                                            <Select
                                                labelId="select-label-factor"
                                                id="select-factor"
                                                name={"factorId"}
                                                label={`${t("pages.admin.dictionary.selects.factor")} *`}
                                                disabled={readOnly}
                                                size={"small"}
                                                value={convert(formik.values.factorId) || ''}
                                                sx={{"& .MuiSelect-iconOutlined": {display: formik.values.factorId? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                                                endAdornment={<IconButton sx={{visibility: formik.values.factorId? "visible": "hidden"}} onClick={(e) =>{
                                                    e.preventDefault()
                                                    formik.setFieldValue("factorId", null, true)
                                                    formik.setFieldValue("subFactorId", null, true)
                                                }}><ClearIcon/></IconButton>}
                                                onChange={(value) => {
                                                    formik.setFieldValue("factorId", Number(value.target.value), true)
                                                    formik.setFieldValue("subFactorId", null, true)
                                                    getDictSubFactors(Number(value.target.value))
                                                }}>
                                                {
                                                    dictFactors.map((value, index) => (
                                                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label-subFactor">{`${t("pages.admin.dictionary.selects.subFactor")}`}</InputLabel>
                                            <Select
                                                label={`${t("pages.admin.dictionary.selects.subFactor")}`}
                                                labelId="select-label-subFactor"
                                                id="select-subFactor"
                                                name={"subFactorId"}
                                                size={"small"}
                                                value={convert(formik.values.subFactorId) || ''}
                                                sx={{"& .MuiSelect-iconOutlined": {display: formik.values.subFactorId? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                                                endAdornment={<IconButton sx={{visibility: formik.values.subFactorId? "visible": "hidden"}} onClick={(e) =>{
                                                    e.preventDefault()
                                                    formik.setFieldValue("subFactorId", null, true)
                                                }}><ClearIcon/></IconButton>}
                                                onChange={(value) => {
                                                    formik.setFieldValue("subFactorId", Number(value.target.value), true)
                                                }}>
                                                {
                                                    dictSubFactors.map((value, index) => (
                                                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label-category">{`${t("pages.admin.dictionary.selects.category")} *`}</InputLabel>
                                            <Select
                                                labelId="select-label-category"
                                                id="select-category"
                                                name={"categoryId"}
                                                size={"small"}
                                                label={`${t("pages.admin.dictionary.selects.category")} *`}
                                                disabled={readOnly}
                                                value={convert(formik.values.categoryId) || ''}
                                                sx={{"& .MuiSelect-iconOutlined": {display: formik.values.categoryId? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                                                endAdornment={<IconButton sx={{visibility: formik.values.categoryId? "visible": "hidden"}} onClick={(e) =>{
                                                    e.preventDefault()
                                                    formik.setFieldValue("categoryId", null, true)
                                                    formik.setFieldValue("subCategoryId", null, true)
                                                }}><ClearIcon/></IconButton>}
                                                onChange={(value) => {
                                                    formik.setFieldValue("categoryId", Number(value.target.value), true)
                                                    formik.setFieldValue("subCategoryId",null, true)
                                                    getDictSubCategories(Number(value.target.value))
                                                }}>
                                                {
                                                    dictCategories.map((value, index) => (
                                                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label-subCategory">{`${t("pages.admin.dictionary.selects.subCategory")}`}</InputLabel>
                                            <Select
                                                labelId="select-label-subCategory"
                                                id="select-subCategory"
                                                name={"subCategoryId"}
                                                label={`${t("pages.admin.dictionary.selects.subCategory")}`}
                                                size={"small"}
                                                value={convert(formik.values.subCategoryId) || ''}
                                                sx={{"& .MuiSelect-iconOutlined": {display: formik.values.subCategoryId? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                                                endAdornment={<IconButton sx={{visibility: formik.values.subCategoryId? "visible": "hidden"}} onClick={(e) =>{
                                                    e.preventDefault()
                                                    formik.setFieldValue("subCategoryId", null, true)
                                                }}><ClearIcon/></IconButton>}
                                                onChange={(value) => {
                                                    formik.setFieldValue("subCategoryId", Number(value.target.value), true)
                                                }}>
                                                {
                                                    dictSubCategories.map((value, index) => (
                                                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            value={indicator}
                                            size={"small"}
                                            options={ dictIndicators }
                                            getOptionLabel={ (option: any)  => option.nameRu }
                                            onChange={(e, newValue: { nameRu: string, nameKz: string, id: number | null } | null) => {
                                                formik.setFieldValue("indicatorId", Number(newValue?.id), true)
                                                setIndicator(newValue)
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField
                                                    label={`${t("pages.admin.dictionary.selects.indicator")} *`}
                                                    name={"indicatorId"}
                                                    {...params}
                                                />
                                            )}/>
                                    </Grid>
                            </Grid>
                            <Box
                                m={1}
                                //margin
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end">

                                <Button color="primary" variant="contained" type='submit' sx={{mr: '1em'}}>
                                    <>{t("pages.admin.dictionary.buttons.search")}</>
                                </Button>

                                <Button color="primary" variant="contained" onClick={(e) => {
                                    e.preventDefault()
                                    formik.resetForm({ values: { factorId: null, subFactorId: null, categoryId: null, subCategoryId: null, indicatorId: null}})
                                    setSearchParams({}, { replace: true })
                                    getRatings(new URLSearchParams({}))
                                }}>
                                    <>{t("pages.admin.dictionary.buttons.clear")}</>
                                </Button>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Grid item container paddingY={{ xs: 2, sm: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(false)
                                    setTitle(t("pages.admin.dictionary.dialog.title_add"))
                                    setInitialValues(resetValue)
                                    setCommand(RatingCommand.ADD)
                                    setOpen(true)
                                }}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.add")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(true)
                                    setTitle(t("pages.admin.dictionary.dialog.title_view") + row?.id)
                                    setInitialValues(row!!)
                                    setCommand(RatingCommand.VIEW)
                                    setOpen(true)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.view")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(false)
                                    setTitle(t("pages.admin.dictionary.dialog.title_update") + row?.id)
                                    setInitialValues(row!!)
                                    setCommand(RatingCommand.UPDATE)
                                    setOpen(true)

                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.update")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setInitialValues(row!!)
                                    handleRemoveClick(row!!)
                                    setCommand(RatingCommand.REMOVE)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.remove")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setInitialValues(row!!)
                                    navigate(`/reports`)
                                }}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.back")}</>
							</span>
                        </Button>
                    </Grid>
                </Grid>

                <Box>
                    <DataGrid
                        components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
                        sx={{
                            height: 500,
                            "& .MuiDataGrid-cell": {
                                whiteSpace: "pre-wrap"
                            },
                        }}
                        columnBuffer={3}
                        rows={ratings}
                        columns={[
                            Column("pages.admin.dictionary.table.id", "id", 50, columnStyle, t),
                            // Column("pages.admin.dictionary.table.periodNameKz", "periodNameKz", 200, columnStyle, t),
                            // Column("pages.admin.dictionary.table.periodNameRu", "periodNameRu", 200, columnStyle, t),
                            // Column("pages.admin.dictionary.table.year", "year", 100, columnStyle, t),
                            Column("pages.admin.dictionary.table.indicatorNameRu", "indicatorNameRu", 400, columnStyle, t),
                            Column("pages.admin.dictionary.table.factorNameRu", "factorNameRu", 200, columnStyle, t),
                            Column("pages.admin.dictionary.table.subFactorNameRu", "subFactorNameRu", 200, columnStyle, t),
                            Column("pages.admin.dictionary.table.categoryNameRu", "categoryNameRu", 200, columnStyle, t),
                            Column("pages.admin.dictionary.table.subCategoryNameRu", "subCategoryNameRu", 200, columnStyle, t),
                        ]}
                        onRowClick={(rowData) => setRow(rowData.row)}
                        page={page} onPageChange={onPageChange}
                        pageSize={pageSize} onPageSizeChange={onPageSizeChange}
                        rowCount={totalElements} rowsPerPageOptions={[5, 10, 15]}
                        loading={loading} paginationMode="server"
                        density="comfortable" style={{ whiteSpace: "pre-wrap" }}
                    />
                </Box>
            </Container>

            <FullScreenDialog
                open={open}
                setOpen={setOpen}
                form={<Form command={command} initialValues={initialValues} readOnly={readOnly} snack={snack}
                            reportId={reportId || ''}/>}
                title={title}
                onClose={onClose}
                t={t}/>
        </Box>
    )
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
