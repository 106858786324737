import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import JoditReact from "jodit-react-ts";
import "jodit/build/jodit.min.css";
import sanitizeHtml from 'sanitize-html';
import {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";

export default function Form({onSubmit, content, setContent, contentTitle, setContentTitle}: {onSubmit: (e: string) => void, content: string,
    setContent: (value: string) => void, contentTitle: string, setContentTitle: (value: string) => void}) {
    const { t } = useTranslation();
    const divRef = useRef<HTMLDivElement | null>(null)
    const [contentHtml, setContentHtml] = useState('')
    function clean(content: string) {
        return sanitizeHtml(content, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'ul', 'li', 'br'],
            allowedAttributes: {
                'a': ["href", "target", "style"],
                'p': ["style"],
                'div': ["style"],
                'h1': ["style"],
                'h2': ["style"],
                'h3': ["style"],
                'h4': ["style"],
                'h5': ["style"],
                'h6': ["style"],
                'ul': ["style"],
                'li': ["style"]
            },
            allowedStyles: {
                'p': {
                    // Match HEX and RGB
                    'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                    'text-align': [/^left$/, /^right$/, /^center$/],
                    // Match any number with px, em, or %
                    'font-size': [/^\d+(?:px|em|%)$/],
                    'margin': [/^\d+(?:px|em|%)$/],
                    'margin-left': [/^\d+(?:px|em|%)$/],
                    'margin-right': [/^\d+(?:px|em|%)$/],
                    'margin-top': [/^\d+(?:px|em|%)$/],
                    'margin-bottom': [/^\d+(?:px|em|%)$/],
                    'font-weight': [/^\d+/],
                },
            }
        });
    }

    return <>
            <Box my={{xs: '2rem'}}>
                <CssTextField
                    label={`${t("pages.admin.dictionary.inputs.title")} *`}
                    name={'title'}
                    sx={{ mb: '1.5rem', mr: '1.5rem' }}
                    fullWidth color="primary" variant="outlined" size="small"
                    value={contentTitle}
                    onChange={(value) => setContentTitle(value.target.value)}
                />
            </Box>
            <Box my={{xs: '2rem'}}>
                <JoditReact onChange={(content) => {
                    setContent(content)
                }} defaultValue={content} />
            </Box>
            <Box id={"box-html"} my={{xs: '2rem'}} ref={divRef}>
                <div  style={{
                        // height: '297mm',
                        // width: '210mm',
                        // marginLeft: 'auto',
                        // marginRight: 'auto'
                    }}>
                     <h1 style={{textAlign: 'center', margin: '20px 0'}}>{contentTitle}</h1>
                     <div id={"form-content"} className={"fulljustify"} dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </Box>
            <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <LoadingButton
                    loading={false}
                    onClick={(e) => {
                        //@ts-ignore
                        // $('#form-content').uncolumnize()

                        setTimeout(() =>{
                            //@ts-ignore
                            $('#form-content').columnize({columns: 2, width: '100mm'})
                            onSubmit(document.getElementById("box-html")?.innerHTML || '')}, 100)

                    }}
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                        mr: '1.5rem'
                    }}>
                    <>{t("pages.admin.dictionary.buttons.insert")}</>
                </LoadingButton>
            </Box>
    </>
}

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
})
