import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
// Screens
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import 'aos/dist/aos.css';
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";

export default function App() {
  return (
      <HelmetProvider>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"anonymous"} />
            <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
          </Helmet>
          <ConfirmProvider
              defaultOptions={{confirmationButtonProps: { autoFocus: true } }}>
              <SnackbarProvider
                  maxSnack={5} autoHideDuration={2500}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Router>
                        <Routes />
                    </Router>
              </SnackbarProvider>
          </ConfirmProvider>
      </HelmetProvider>
  );
}

