import {DictionaryObject} from "./dictionary";

export type ReportData = {
    id: number | null,
    nameKz: string,
    nameRu: string,
    year: number | null,
    periodId: number | null,
    periodNameKz: string,
    periodNameRu: string,
    createdBy: string,
    createdAt: string
    updatedBy: string | null
    updatedAt: string | null
}


export type RatingData = {
    id: number | null
    year: number | null
    periodId: number | null
    periodNameKz: string
    periodNameRu: string
    factorId: number | null
    factorNameKz: string
    factorNameRu: string
    subFactorId: number | null
    subFactorNameKz: string
    subFactorNameRu: string
    categoryId: number | null
    categoryNameKz: string
    categoryNameRu: string
    subCategoryId: number | null
    subCategoryNameKz: string
    subCategoryNameRu: string
    indicatorId: number | null
    indicatorNameKz: string
    indicatorNameRu: string
    regionValue: number | null
    balhashValue: number | null
    balhashScore: number | null
    balhashPlace: number | null
    enbekValue: number | null
    enbekScore: number | null
    enbekPlace: number | null
    zhanbylValue: number | null
    zhanbylScore: number | null
    zhanbylPlace: number | null
    iliValue: number | null
    iliScore: number | null
    iliPlace: number | null
    karasayValue: number | null
    karasayScore: number | null
    karasayPlace: number | null
    kegenValue: number | null
    kegenScore: number | null
    kegenPlace: number | null
    raymbekValue: number | null
    raymbekScore: number | null
    raymbekPlace: number | null
    talgarValue: number | null
    talgarScore: number | null
    talgarPlace: number | null
    uygurValue: number | null
    uygurScore: number | null
    uygurPlace: number | null
    konaevValue: number | null
    konaevScore: number | null
    konaevPlace: number | null
}

export const initialValueRating = {id: null,
    year: null,
    periodId: null,
    periodNameKz: '',
    periodNameRu: '',
    factorId: null,
    factorNameKz: '',
    factorNameRu: '',
    subFactorId: null,
    subFactorNameKz: '',
    subFactorNameRu: '',
    categoryId: null,
    categoryNameKz: '',
    categoryNameRu: '',
    subCategoryId: null,
    subCategoryNameKz: '',
    subCategoryNameRu: '',
    indicatorId: null,
    indicatorNameKz: '',
    indicatorNameRu: '',
    regionValue: null,
    balhashValue: null,
    balhashScore: null,
    balhashPlace: null,
    enbekValue: null,
    enbekScore: null,
    enbekPlace: null,
    zhanbylValue: null,
    zhanbylScore: null,
    zhanbylPlace: null,
    iliValue: null,
    iliScore: null,
    iliPlace: null,
    karasayValue: null,
    karasayScore: null,
    karasayPlace: null,
    kegenValue: null,
    kegenScore: null,
    kegenPlace: null,
    raymbekValue: null,
    raymbekScore: null,
    raymbekPlace: null,
    talgarValue: null,
    talgarScore: null,
    talgarPlace: null,
    uygurValue: null,
    uygurScore: null,
    uygurPlace: null,
    konaevValue: null,
    konaevScore: null,
    konaevPlace: null
}

export type RatingFactorData = {
    score: number;
    place: number;
    factorNameRu: string;
    factorNameKz: string;
    regionId: number;
}


export type RatingSubFactorData = {
    score: number;
    place: number;
    subFactorNameRu: string;
    subFactorNameKz: string;
    regionId: number;
}

export type RatingCategoryData = {
    score: number;
    place: number;
    categoryNameRu: string;
    categoryNameKz: string;
    regionId: number;
}

export type RatingTotalData = {
    score: number;
    place: number;
    regionNameRu: string;
    regionNameKz: string;
    regionId: number;
    factors: RatingFactorData[]
    subFactors: RatingSubFactorData[]

    categories: RatingCategoryData[]
}
export type ViewData = {
    subFactors: DictionaryObject[]
    categories: DictionaryObject[]
    totals: RatingTotalData[]

    tableCategories: any[] | null
    lastPlaces: any[] | null
}


export enum RatingCommand {
    ADD,
    UPDATE,
    VIEW,
    REMOVE
}

export type RatingSelect = {
    factorId: number | null,
    subFactorId: number | null,
    categoryId: number | null,
    subCategoryId: number | null
}


export type RegionReport = {
    regionId: number,
    nameRu: string,
    nameKz: string,
    indicators: RegionIndicatorReport[]
}

export type RegionIndicatorReport = {
    indicatorNameRu: string,
    indicatorNameKz: string,
    score: number
}

export type DistrictReport = {
    factors: DistrictFactorReport[],
    subFactors: DistrictSubFactorReport[]
}

export type DistrictFactorReport = {
    factorNameRu: string,
    factorNameKz: string,
    score: number
}

export type DistrictSubFactorReport = {
    subFactorNameRu: string,
    subFactorNameKz: string,
    score: number
}


export type IndicatorReport = {
    indicatorId: number,
    nameRu: string,
    nameKz: string,
    regions: IndicatorRegionReport[]
}

export type IndicatorRegionReport = {
    regionNameRu: string,
    regionNameKz: string,
    score: number
}
