import Services from "../components/Services";
import Projects from "../components/Projects";
import Blog from "../components/Blog";
import Header from "../components/Header";

export default function Home() {
    return (
        <>
            <Header/>
            <Services />
            <Projects />
            <Blog />
        </>
    );
}
