import { useTranslation } from "react-i18next";
import { Pages } from "../model/pages";

export function useTranslatePages() {
    const { t } = useTranslation();

    function translatePages(pages: Pages) {
        return pages.map((page1, idx, array) => (
            {
                title: t(page1.title),
                id: page1.id,
                pages: page1.pages.map((page2, idx) => (
                    {
                        title: t(page2.title),
                        href: page2.href,
                        target: page2.target,
                    }
                ))
            }
        ));
    }

    return { translatePages }
}
