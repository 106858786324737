export const light = {
    alternate: {
        main: '#f7f9fc',
        dark: '#edf1f7',
    },
    cardShadow: 'rgba(23, 70, 161, .11)',
    mode: 'light',
    primary: {
        main: '#f57c00',
        light: '#ff9800',
        dark: '#e65100',
        contrastText: '#fff',
    },
    secondary: {
        main: '#00AB55',
        light: '#33CC70',
        dark: '#009357',
        contrastText: '#fff',
    },
    text: {
        primary: '#2d3748',
        secondary: '#646e73',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
        paper: '#fff',
        default: '#fff',
        level2: '#f5f5f5',
        level1: '#fff',
    },
};

export const dark = {
    alternate: {
        main: '#1a2138',
        dark: '#151a30',
    },
    cardShadow: 'rgba(0, 0, 0, .11)',
    common: {
        black: '#000',
        white: '#fff',
    },
    mode: 'dark',
    primary: {
        main: '#f57c00',
        light: '#ff9800',
        dark: '#e65100',
        contrastText: '#fff',
    },
    secondary: {
        main: '#00AB55',
        light: '#33CC70',
        dark: '#009357',
        contrastText: '#fff',
    },
    text: {
        primary: '#EEEEEF',
        secondary: '#AEB0B4',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: {
        paper: '#222B45',
        default: '#222B45',
        level2: '#333',
        level1: '#2D3748',
    },
}
