import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material';
import { ruRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
import shadows from './shadows';
import palette from './palette';

const getTheme = (mode: any, paletteType: any) =>
    responsiveFontSizes(
        createTheme({
            palette: palette(mode, paletteType) as any,
            layout: {
                contentWidth: 1236,
            },
            shadows: shadows(mode),
            typography: {
                fontFamily: '"Inter", sans-serif',
                button: {
                    textTransform: 'none',
                    fontWeight: 'medium',
                },
            },
            zIndex: {
                appBar: 1200,
                drawer: 1300,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        label: {
                            fontWeight: 600,
                        },
                        containedSecondary: mode === 'light' ? { color: 'white' } : {},
                    },
                },
            },
        } as any, ruRU, coreRuRU),
    );

export default getTheme
