import React from 'react';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '../../../common/Container';
import { useTranslation } from 'react-i18next';
import {i18translateType} from "../../../i18n";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const { t }: i18translateType = useTranslation();
    const navigate = useNavigate()

    return (
        <Box
            minHeight={'calc(100vh - 64px - 100px)'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            bgcolor={theme.palette.background.default}
        >
            <Container>
                <Grid container spacing={6}>
                    <Grid item container justifyContent={'center'} xs={12} md={6}>
                        <Box
                            height={'100%'}
                            width={'100%'}
                            maxWidth={{ xs: 500, md: '100%' }}
                        >
                        </Box>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        xs={12}
                        md={6}
                    >
                        <Box>
                            <Typography
                                variant="h1"
                                component="h1"
                                align={isMd ? 'left' : 'center'}
                                sx={{ fontWeight: 700 }}
                            >
                                404
                            </Typography>
                            <Typography
                                variant="h6"
                                component="p"
                                color="textSecondary"
                                align={isMd ? 'left' : 'center'}
                            >
                                {t("not_found_page.page_doesnt_exists")}
                                <br />
                                {t("not_found_page.if_something_wrong")}, {t("not_found_page.please")}{' '}
                                <Link href={''} underline="none">
                                    {t("not_found_page.tell_us")}
                                </Link>
                            </Typography>
                            <Box
                                marginTop={4}
                                display={'flex'}
                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                            >
                                <Button
                                    component="button"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => navigate("/")}>
                                    {t("not_found_page.to_main_page")}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default NotFound;
