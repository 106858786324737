import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { authReducer } from "./reducers/auth";
import { reportReducer } from "./reducers/report";
import {ratingReducer} from "./reducers/rating";

const rootReducer = combineReducers({
    auth: authReducer,
    report: reportReducer,
    rating: ratingReducer,
});

export const store = createStore(
    rootReducer,
    composeWithDevTools()
);

export type State = ReturnType<typeof rootReducer>;
