import { Button, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { CSSProperties } from "react";
import NumberFormat from "react-number-format";
import {TFunction} from "i18next";
import {i18translateType} from "../i18n";

export const columnStyle: CSSProperties = {
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
};

export const largeColumnStyle: CSSProperties = {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "5px 0"
}

const innerStyle: CSSProperties = {
    display: "flex",
}

export function Column(
    headerName: string,
    field: string,
    width: number,
    style: CSSProperties,
    t: TFunction<"translation", undefined>,
    tDictionaryKey?: string,
) {
    return {
        field, headerName: t(`${headerName}`), width,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
            <Tooltip title={tDictionaryKey ? t(`${tDictionaryKey}.${params.row[field]}`) as string : params.row[field]}>
                <div style={style}>
                    <div style={innerStyle}>
                        {tDictionaryKey
                            ? t(`${tDictionaryKey}.${params.row[field]}`)
                            : params.row[field]}
                    </div>
                </div>
            </Tooltip>
        ),
    }
}

export function DateColumn(
    field: string,
    width: number,
    style: CSSProperties,
    t: TFunction<"translation", undefined>,
) {
    return {
        field, headerName: t(`auction.table_columns.${field}`), width,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
            <Tooltip title={params.row[field].replace('T', " ") ?? params.row[field]}>
                <div style={style}>
                    <div style={innerStyle}>{params.row[field].replace('T', " ") ?? params.row[field]}</div>
                </div>
            </Tooltip>
        ),
    }
}

export function AuthoritiesColumn(
    width: number,
    style: CSSProperties,
    t: TFunction<"translation", undefined>,
) {
    return {
        field: "authorities", headerName: t("auction.table_columns.authorities"), width,
        renderCell: (params: GridRenderCellParams<any, any, any>) => (
            <Tooltip title={t(`authority.${params.row.authorities[params.row.authorities.length - 1]}`) as string}>
                <div style={style}>
                    <div style={innerStyle}>
                        <>{t(`authority.${params.row.authorities[params.row.authorities.length - 1]}`)}</>
                    </div>
                </div>
            </Tooltip>
        ),
    }
}
