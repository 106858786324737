import {useParams} from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {Autocomplete, Box, Button, Grid, TextField} from "@mui/material";
import {DictionaryObject} from "../../../model/dictionary";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from '@emotion/styled';
import {
    IndicatorReport, RatingTotalData,
} from "../../../model/report";
import {reportService} from "../../../api/services/report";
import Container from "../../../common/Container";
import debounce from 'lodash.debounce';
import {PairTable, Table} from "../../report/View/View";
import {CssTextField} from "../../../components/TextField/CssTextField";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);
export default function Region() {

    const { district } = useParams();
    const [year, setYear] = useState<number | null>(null)
    const [period, setPeriod] = useState<DictionaryObject | null>(null)
    const [report, setReport] = useState<DictionaryObject | null>(null)
    const [regionReport, setRegionReport] = useState<IndicatorReport[]>([])
    const [indicators, setIndicators] = useState<DictionaryObject[]>(
        [])
    const [dictIndicators, setDictIndicators] = useState<DictionaryObject[]>([])
    const [dictReports, setDictReports] = useState<DictionaryObject[]>([])
    const [totals, setTotals] = useState<RatingTotalData[]>([])
    async function getDictIndicators() {
        try {
            const response = await reportService.getDictIndicators()
            if(response.status === 'success' && response.data) {
                setDictIndicators(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictPeriods() {
        try {
            const response = await reportService.getDictPeriods()
            if(response.status === 'success' && response.data) {
                setDictPeriods(response.data)
            }
        } catch (ignored) {}
    }

    const { t } = useTranslation();
    const [dictPeriods, setDictPeriods] = useState<DictionaryObject[]>([])
    const [tableCategories, setTableCategories] = useState<PairTable[]>([])

    useEffect(() => {
        getDictPeriods()
        getDictIndicators()

        return () => {
            constYearChangeHandler.cancel()
        }

    }, [district])

    async function search() {
        if(!year || !period || !report) {
            return
        }
        if(!district) {
            try {
                const response = await reportService.getTotals(report!.id)
                if(response.status === 'success' && response.data) {
                    const categories = response.data
                    if(categories && categories.length > 0) {
                        const keys = Object.keys(categories[0])
                        const arrCount = Math.ceil((keys.length - 1) / 4)
                        let start = 1
                        let end = 5
                        let tempTable: any[] = []
                        let pair: PairTable = {left: null, right: null}
                        for(let i = 0; i < arrCount; i++) {
                            let right = pairTable(start, end, keys, categories)
                            start = end
                            end = end + 4;
                            let left =  pairTable(start, end, keys, categories)
                            start = end
                            end = end + 4;
                            pair = {right, left}
                            i++
                            tempTable.push(pair)
                        }
                        setTableCategories(tempTable)
                    }
                }
            } catch (ignored) {}
        } else {
            const response = await reportService.getRegionReport(report!.id, indicators.map(indicator => indicator.id).join(",") || "");
            if (response.status === 'success' && response.data) {
                setRegionReport(response.data)
            }
        }
    }

    const pairTable = (start: number, end: number, keys: string[], tableCategories: any[] | null): {columns: Set<string>, rows: Map<string, any[]> } | null=> {
        let labels: string[] = keys.slice(start, end);
        if(labels.length === 0) {
            return null
        }
        let columns: Set<string> = new Set<string>(labels)
        let rows: Map<string, any> = new Map<string, any>()
        for(let category of tableCategories || []) {
            let tempRowArr = []
            for(let key of labels) {
                tempRowArr.push(category[key])
            }
            rows.set(category.name_ru, tempRowArr)
        }
        return {columns, rows}
    }

    async function getDictReports(year: number, periodId: number) {
        try {
            const response = await reportService.getDictReport(year, periodId)
            if(response.status === 'success' && response.data) {
                setDictReports(response.data)
            }
        } catch (ignored) {}
    }

    const constYearChangeHandler = useMemo(() => debounce(() => {
            if(!period) {
                //@ts-ignore
                getDictReports(year, period?.id)
            }
        }, 1000)
        , [year, period]);

    const Charts = ()  => {
        if(!district) {
            return <AlmatyRegion tableCategories={tableCategories} year={year} period={period} report={report}/>
        }

        return <Districts regionReport={regionReport} year={year} period={period}/>
    }
    return <Container>
        <Grid item marginBottom={2} container spacing={2} alignItems={"flex-start"}>
            <Grid item xs={12} sm={1}>
                <CssTextField
                    label={`${t("pages.admin.dictionary.inputs.year")} *`}
                    name={'year'}
                    type="number"
                    sx={{ mb: '1.5rem', mr: '1.5rem' }}
                    fullWidth color="primary" variant="outlined" size="small"
                    value={year}
                    onChange={(value) => {
                        setYear(Number(value.target.value))
                        if(period) {
                            constYearChangeHandler()
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>

                <Autocomplete
                    getOptionLabel={ (option: any)  => option.nameRu }
                    size="small" sx={{ mb: .5 }}
                    options={dictPeriods}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={period}
                    onChange={(e, newValue: DictionaryObject | null) => {
                        setPeriod(newValue)
                        if(year) {
                            getDictReports(year!, newValue!.id)
                        }
                    }}
                    renderInput={params =>
                        <TextField {...params} fullWidth color="primary"
                                   name="period" label={`${t("pages.admin.dictionary.selects.period")}`}/>
                    }
                />
            </Grid>
            { !!district &&
                <Grid item xs={12} sm={6}>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            value={indicators}
                            size={"small"}
                            options={ dictIndicators }
                            getOptionLabel={ (option: any)  => `${option.id} ${option.nameRu}` }
                            onChange={(event, newValue: DictionaryObject[]) => setIndicators(newValue)}
                            renderInput={(params: any) => (
                                <TextField
                                    label={`${t("pages.admin.dictionary.selects.indicator")} *`}
                                    name={"indicatorId"}
                                    {...params}
                                />
                            )}/>
                </Grid>
            }
            <Grid item xs={12} sm={5}>

                <Autocomplete
                    getOptionLabel={ (option: any)  => option.nameRu }
                    size="small" sx={{ mb: .5 }}
                    options={dictReports}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={report}
                    onChange={(e, newValue: DictionaryObject | null) => {
                        setReport(newValue)
                    }}
                    renderInput={params =>
                        <TextField {...params} fullWidth color="primary"
                                   name="period" label={`${t("pages.admin.dictionary.selects.report")} *`}/>
                    }
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                <Button fullWidth color="primary" variant="contained"
                        onClick={() => search()}
                >
                    <>{t("pages.admin.dictionary.buttons.search")}</>
                </Button>
            </Grid>
        </Grid>

        <Charts />
    </Container>
}

function AlmatyRegion(props: { tableCategories: any[], period: DictionaryObject | null, year: number | null,
    report: any}) {

    const { tableCategories, year, period, report } = props

    const { t } = useTranslation();

    return <>
        { tableCategories.length > 0 &&
            <>
                <Grid item xs={12} sm={1} style={{marginBottom: '4rem'}}>
                    <Button  color="primary" variant="contained"
                            onClick={() => reportService.downloadRatingPlace(report?.id || 0)}
                    >
                        <>{t("pages.public.buttons.downloadReport")}</>
                    </Button>
                </Grid>

                { tableCategories.map((val: PairTable, index: number) => (
                    <div id="report-table-category"  key={index} style={{
                        // width: '210mm',
                        display: 'block',
                        clear: 'both',
                        pageBreakAfter: 'always',
                        fontFamily: 'Times New Roman", Times, serif',
                        // marginLeft: 'auto',
                        // marginRight: 'auto'
                    }}>

                        { !!val.right &&
                            <>
                                <p>Рисунок {index + 1 + index} – Рейтинг районов за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года в разрезе категорий</p>
                                <Table rows={val.right.rows} label={"Категория"} columns={val.right.columns}/>
                            </>
                        }
                        { !!val.left &&
                            <>
                                <p>Рисунок {index + 2 + index} – Рейтинг районов за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года в разрезе категорий</p>
                                <Table rows={val.left.rows} label={"Категория"} columns={val.left.columns}/>
                            </>
                        }
                    </div>
                ))
                }
            </>
        }
        </>
}

function Districts(props: { regionReport: IndicatorReport[], period: DictionaryObject | null, year: number | null}) {

    const { regionReport, period, year } = props
    const hasValue = regionReport.length > 0
    const options = {
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false
            },
            datalabels: {
                display: true,
                color: 'white',
                font: {
                    weight: 600
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const labels = hasValue ? regionReport[0].regions.map(indicator => indicator.regionNameRu) : [];

    const dataList = regionReport.map((indicator: IndicatorReport) => {
        return {
            labels,
            datasets: [{
                label: indicator.nameRu,
                data: indicator.regions.map(region => region.score),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 99, 71, 0.8)',
                    'rgba(90, 90, 90, 0.8)',
                    'rgba(255, 231, 71, 0.8)',
                    'rgba(60, 179, 113, 0.8)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 99, 71, 1)',
                    'rgba(90, 90, 90, 1)',
                    'rgba(255, 231, 71, 1)',
                    'rgba(60, 179, 113, 1)'
                ],
            }]
        }
    })

    function randomRgba() {
        let o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ', 1)';
    }

    return <>
        { dataList.length > 0 &&
            <p>Рисунок 1 – Рейтинг районов за <span style={{textTransform: 'lowercase'}}>{period?.nameRu}</span> {year} года</p>
        }
        {
            dataList.map((data: any, index: number) => (
                <Box key={`${index}`}>
                    <p style={{fontWeight: 600}}>{data.datasets[0]?.label}</p>
                    <Bar style={{marginBottom: '4rem'}} options={options} data={data} />
                </Box>
            ))
        }
    </>
}
