import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {TFunction} from "i18next";

const styles = {
    root: { top: `64px !important` }
}


const Transition = React.forwardRef(function Transition(props: any, ref: any) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog({open, setOpen, title, form, t, onClose}:
                              {open: boolean, setOpen: (value: boolean) => void, title: string, form: JSX.Element,
                               t: TFunction, onClose?: Function}) {

    const handleClose = () => {
        setOpen(false);
        if(onClose) {
            onClose()
        }
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>
                    {form}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        <>{t("modals.common.cancel")}</>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



export default FullScreenDialog;
