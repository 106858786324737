import {ReactElement, useState} from "react";
import {useTheme} from "@mui/material/styles";
import PropTypes from "prop-types";
import {Authority} from "../../utils/common";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Container from '../../common/Container';
import { Sidebar } from './components';
import { Topbar, Footer } from '../components'
import { pages as accountPages } from '../navigation--account';
import { pages as adminPages } from '../navigation--admin';
import {Divider} from "@mui/material";

const Cabinet = ({
                     children,
                     themeToggle,
                     themeMode,
                     setThemePalette,
                     paletteType,
                     role
              }: {
    children: ReactElement;
    themeToggle: () => void;
    themeMode: "light" | "dark";
    setThemePalette: (type?: string) => void;
    paletteType: string;
    role: Authority;
}) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState(false);
    const handleSidebarOpen = () => setOpenSidebar(true);
    const handleSidebarClose = () => setOpenSidebar(false);

    function rolePages() {
        switch (role) {
            case Authority.USER: return accountPages
            case Authority.ADMIN: return adminPages
            default: return accountPages
        }
    }


    return (
        <>
            <Box height="100%" width="100%" style={{minHeight: 'calc(100vh - 100px)'}}>
                <AppBar
                    position={'relative'}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                    elevation={0}>
                    <Container paddingY={{ xs: 1 / 2, sm: 1 }} maxWidth={{ md: '100%' }}>
                        <Topbar
                            onSidebarOpen={handleSidebarOpen}
                            themeMode={themeMode}
                            themeToggle={themeToggle}
                            setThemePalette={setThemePalette}
                            paletteType={paletteType}
                            removeLandingLinks={true}
                        />
                        <Divider/>
                    </Container>
                </AppBar>
                <Sidebar
                    onClose={handleSidebarClose}
                    open={openSidebar}
                    variant={isMd ? 'permanent' : 'temporary'}
                    pages={rolePages()}
                    themeMode={themeMode}
                />
                <main>
                    <Box
                        display="flex"
                        flex="1 1 auto"
                        paddingLeft={{ md: '256px' }}>
                        <Box display="flex" flex="1 1 auto">
                            <Box flex="1 1 auto" display="flex" flexDirection="column" justifyContent="space-between">
                                {children}
                            </Box>
                        </Box>
                    </Box>
                </main>

            </Box>
            <Footer />
        </>
    )
}

Cabinet.propTypes = {
    children: PropTypes.node,
    themeToggle: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
    setThemePalette: PropTypes.func.isRequired,
    paletteType: PropTypes.string.isRequired,
};

export default Cabinet
