import * as React from "react";

function SvgComponent(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width={39.581} height={39.58} viewBox="0 0 433.69 433.69">
          <g>
              <g id="XMLID_28_">
                  <g>
                      <path d="M430.01,46.13v298.74c0,25.44-20.69,46.13-46.13,46.13H119.31c-7.08,24.62-29.79,42.69-56.66,42.69
				c-32.52,0-58.97-26.45-58.97-58.96V91.35c0-20.84,15.81-38.06,36.07-40.31v-4.91C39.75,20.69,60.44,0,85.88,0h298
				C409.32,0,430.01,20.69,430.01,46.13z M421.01,344.87V46.13C421.01,25.66,404.36,9,383.88,9h-298C65.4,9,48.75,25.66,48.75,46.13
				v298.74c0,20.48,16.65,37.13,37.13,37.13h26.2c0.35-2.37,0.53-4.8,0.53-7.27v-2.13H91.7c-16.47,0-29.87-13.4-29.87-29.87V48.27
				c0-16.47,13.4-29.87,29.87-29.87h50.83c16.47,0,29.87,13.4,29.87,29.87v294.46c0,16.47-13.4,29.87-29.87,29.87h-20.92v2.13
				c0,2.46-0.17,4.89-0.46,7.27h262.73C404.36,382,421.01,365.35,421.01,344.87z M163.4,342.73V48.27
				c0-11.51-9.36-20.87-20.87-20.87H91.7c-11.51,0-20.87,9.36-20.87,20.87v294.46c0,11.51,9.36,20.87,20.87,20.87h50.83
				C154.04,363.6,163.4,354.24,163.4,342.73z M109.88,391h-24c-25.44,0-46.13-20.69-46.13-46.13V60.11
				C24.46,62.3,12.68,75.47,12.68,91.35v283.38c0,27.55,22.41,49.96,49.97,49.96C84.5,424.69,103.11,410.59,109.88,391z"/>
                      <path fill="#4489D3" d="M421.01,46.13v298.74c0,20.48-16.65,37.13-37.13,37.13H121.15c0.29-2.38,0.46-4.81,0.46-7.27
				v-2.13h20.92c16.47,0,29.87-13.4,29.87-29.87V48.27c0-16.47-13.4-29.87-29.87-29.87H91.7c-16.47,0-29.87,13.4-29.87,29.87v294.46
				c0,16.47,13.4,29.87,29.87,29.87h20.91v2.13c0,2.47-0.18,4.9-0.53,7.27h-26.2c-20.48,0-37.13-16.65-37.13-37.13V46.13
				C48.75,25.66,65.4,9,85.88,9h298C404.36,9,421.01,25.66,421.01,46.13z M409.4,173.39V48.27c0-16.47-13.39-29.87-29.87-29.87
				H257.74c-16.47,0-29.87,13.4-29.87,29.87v125.12c0,16.47,13.4,29.87,29.87,29.87h121.79C396.01,203.26,409.4,189.86,409.4,173.39
				z M409.26,339.93v-15.06c0-5.83-4.75-10.58-10.59-10.58h-33.22c-5.84,0-10.59,4.75-10.59,10.58v15.06
				c0,5.84,4.75,10.59,10.59,10.59h33.22C404.51,350.52,409.26,345.77,409.26,339.93z M409.26,291.36V276.3
				c0-5.84-4.75-10.59-10.59-10.59h-33.22c-5.84,0-10.59,4.75-10.59,10.59v15.06c0,5.83,4.75,10.58,10.59,10.58h33.22
				C404.51,301.94,409.26,297.19,409.26,291.36z M409.26,242.04v-15.06c0-5.84-4.75-10.59-10.59-10.59h-33.22
				c-5.84,0-10.59,4.75-10.59,10.59v15.06c0,5.84,4.75,10.59,10.59,10.59h33.22C404.51,252.63,409.26,247.88,409.26,242.04z
				 M345.84,339.93v-15.06c0-5.83-4.75-10.58-10.59-10.58h-33.22c-5.84,0-10.59,4.75-10.59,10.58v15.06
				c0,5.84,4.75,10.59,10.59,10.59h33.22C341.09,350.52,345.84,345.77,345.84,339.93z M345.84,291.36V276.3
				c0-5.84-4.75-10.59-10.59-10.59h-33.22c-5.84,0-10.59,4.75-10.59,10.59v15.06c0,5.83,4.75,10.58,10.59,10.58h33.22
				C341.09,301.94,345.84,297.19,345.84,291.36z M345.84,242.04v-15.06c0-5.84-4.75-10.59-10.59-10.59h-33.22
				c-5.84,0-10.59,4.75-10.59,10.59v15.06c0,5.84,4.75,10.59,10.59,10.59h33.22C341.09,252.63,345.84,247.88,345.84,242.04z
				 M282.42,339.93v-15.06c0-5.83-4.75-10.58-10.59-10.58h-33.22c-5.84,0-10.59,4.75-10.59,10.58v15.06
				c0,5.84,4.75,10.59,10.59,10.59h33.22C277.67,350.52,282.42,345.77,282.42,339.93z M282.42,291.36V276.3
				c0-5.84-4.75-10.59-10.59-10.59h-33.22c-5.84,0-10.59,4.75-10.59,10.59v15.06c0,5.83,4.75,10.58,10.59,10.58h33.22
				C277.67,301.94,282.42,297.19,282.42,291.36z M282.42,242.04v-15.06c0-5.84-4.75-10.59-10.59-10.59h-33.22
				c-5.84,0-10.59,4.75-10.59,10.59v15.06c0,5.84,4.75,10.59,10.59,10.59h33.22C277.67,252.63,282.42,247.88,282.42,242.04z"/>
                      <path d="M409.4,48.27v125.12c0,16.47-13.39,29.87-29.87,29.87H257.74c-16.47,0-29.87-13.4-29.87-29.87V48.27
				c0-16.47,13.4-29.87,29.87-29.87h121.79C396.01,18.4,409.4,31.8,409.4,48.27z M400.4,173.39V48.27
				c0-11.51-9.36-20.87-20.87-20.87H257.74c-11.51,0-20.87,9.36-20.87,20.87v125.12c0,11.51,9.36,20.87,20.87,20.87h121.79
				C391.04,194.26,400.4,184.9,400.4,173.39z"/>
                      <path d="M409.26,324.87v15.06c0,5.84-4.75,10.59-10.59,10.59h-33.22c-5.84,0-10.59-4.75-10.59-10.59v-15.06
				c0-5.83,4.75-10.58,10.59-10.58h33.22C404.51,314.29,409.26,319.04,409.26,324.87z M400.26,339.93v-15.06
				c0-0.87-0.72-1.58-1.59-1.58h-33.22c-0.88,0-1.59,0.71-1.59,1.58v15.06c0,0.88,0.71,1.59,1.59,1.59h33.22
				C399.54,341.52,400.26,340.81,400.26,339.93z"/>
                      <path d="M409.26,276.3v15.06c0,5.83-4.75,10.58-10.59,10.58h-33.22c-5.84,0-10.59-4.75-10.59-10.58V276.3
				c0-5.84,4.75-10.59,10.59-10.59h33.22C404.51,265.71,409.26,270.46,409.26,276.3z M400.26,291.36V276.3
				c0-0.88-0.72-1.59-1.59-1.59h-33.22c-0.88,0-1.59,0.71-1.59,1.59v15.06c0,0.87,0.71,1.58,1.59,1.58h33.22
				C399.54,292.94,400.26,292.23,400.26,291.36z"/>
                      <path d="M409.26,226.98v15.06c0,5.84-4.75,10.59-10.59,10.59h-33.22c-5.84,0-10.59-4.75-10.59-10.59v-15.06
				c0-5.84,4.75-10.59,10.59-10.59h33.22C404.51,216.39,409.26,221.14,409.26,226.98z M400.26,242.04v-15.06
				c0-0.87-0.72-1.59-1.59-1.59h-33.22c-0.88,0-1.59,0.72-1.59,1.59v15.06c0,0.88,0.71,1.59,1.59,1.59h33.22
				C399.54,243.63,400.26,242.92,400.26,242.04z"/>
                      <path fill="#6BCE95" d="M400.4,48.27v125.12c0,11.51-9.36,20.87-20.87,20.87H257.74c-11.51,0-20.87-9.36-20.87-20.87
				V48.27c0-11.51,9.36-20.87,20.87-20.87h121.79C391.04,27.4,400.4,36.76,400.4,48.27z M392.33,83.65V52.68
				c0-8.75-7.13-15.88-15.88-15.88H260.83c-8.75,0-15.88,7.13-15.88,15.88v30.97c0,8.76,7.13,15.88,15.88,15.88h115.62
				C385.2,99.53,392.33,92.41,392.33,83.65z M392.33,111.96c0-2.48-2.02-4.5-4.5-4.5h-18.4c-2.49,0-4.5,2.02-4.5,4.5
				c0,2.49,2.01,4.5,4.5,4.5h18.4C390.31,116.46,392.33,114.45,392.33,111.96z M392.33,132.82c0-2.49-2.02-4.5-4.5-4.5h-18.4
				c-2.49,0-4.5,2.01-4.5,4.5c0,2.48,2.01,4.5,4.5,4.5h18.4C390.31,137.32,392.33,135.3,392.33,132.82z M392.33,153.67
				c0-2.49-2.02-4.5-4.5-4.5h-18.4c-2.49,0-4.5,2.01-4.5,4.5s2.01,4.5,4.5,4.5h18.4C390.31,158.17,392.33,156.16,392.33,153.67z
				 M392.33,174.52c0-2.48-2.02-4.5-4.5-4.5h-18.4c-2.49,0-4.5,2.02-4.5,4.5c0,2.49,2.01,4.5,4.5,4.5h18.4
				C390.31,179.02,392.33,177.01,392.33,174.52z M272.35,111.96c0-2.48-2.01-4.5-4.5-4.5h-18.4c-2.48,0-4.5,2.02-4.5,4.5
				c0,2.49,2.02,4.5,4.5,4.5h18.4C270.34,116.46,272.35,114.45,272.35,111.96z M272.35,132.82c0-2.49-2.01-4.5-4.5-4.5h-18.4
				c-2.48,0-4.5,2.01-4.5,4.5c0,2.48,2.02,4.5,4.5,4.5h18.4C270.34,137.32,272.35,135.3,272.35,132.82z M272.35,153.67
				c0-2.49-2.01-4.5-4.5-4.5h-18.4c-2.48,0-4.5,2.01-4.5,4.5s2.02,4.5,4.5,4.5h18.4C270.34,158.17,272.35,156.16,272.35,153.67z
				 M272.35,174.52c0-2.48-2.01-4.5-4.5-4.5h-18.4c-2.48,0-4.5,2.02-4.5,4.5c0,2.49,2.02,4.5,4.5,4.5h18.4
				C270.34,179.02,272.35,177.01,272.35,174.52z"/>
                      <path fill="#6BCE95" d="M400.26,324.87v15.06c0,0.88-0.72,1.59-1.59,1.59h-33.22c-0.88,0-1.59-0.71-1.59-1.59v-15.06
				c0-0.87,0.71-1.58,1.59-1.58h33.22C399.54,323.29,400.26,324,400.26,324.87z"/>
                      <path fill="#6BCE95" d="M400.26,276.3v15.06c0,0.87-0.72,1.58-1.59,1.58h-33.22c-0.88,0-1.59-0.71-1.59-1.58V276.3
				c0-0.88,0.71-1.59,1.59-1.59h33.22C399.54,274.71,400.26,275.42,400.26,276.3z"/>
                      <path fill="#6BCE95" d="M400.26,226.98v15.06c0,0.88-0.72,1.59-1.59,1.59h-33.22c-0.88,0-1.59-0.71-1.59-1.59v-15.06
				c0-0.87,0.71-1.59,1.59-1.59h33.22C399.54,225.39,400.26,226.11,400.26,226.98z"/>
                      <path d="M392.33,52.68v30.97c0,8.76-7.13,15.88-15.88,15.88H260.83c-8.75,0-15.88-7.12-15.88-15.88V52.68
				c0-8.75,7.13-15.88,15.88-15.88h115.62C385.2,36.8,392.33,43.93,392.33,52.68z M383.33,83.65V52.68c0-3.79-3.09-6.88-6.88-6.88
				H260.83c-3.79,0-6.88,3.09-6.88,6.88v30.97c0,3.79,3.09,6.88,6.88,6.88h115.62C380.24,90.53,383.33,87.44,383.33,83.65z"/>
                      <path d="M387.83,107.46c2.48,0,4.5,2.02,4.5,4.5c0,2.49-2.02,4.5-4.5,4.5h-18.4c-2.49,0-4.5-2.01-4.5-4.5
				c0-2.48,2.01-4.5,4.5-4.5H387.83z"/>
                      <path d="M387.83,128.32c2.48,0,4.5,2.01,4.5,4.5c0,2.48-2.02,4.5-4.5,4.5h-18.4c-2.49,0-4.5-2.02-4.5-4.5
				c0-2.49,2.01-4.5,4.5-4.5H387.83z"/>
                      <path d="M387.83,149.17c2.48,0,4.5,2.01,4.5,4.5s-2.02,4.5-4.5,4.5h-18.4c-2.49,0-4.5-2.01-4.5-4.5s2.01-4.5,4.5-4.5H387.83z"/>
                      <path d="M387.83,170.02c2.48,0,4.5,2.02,4.5,4.5c0,2.49-2.02,4.5-4.5,4.5h-18.4c-2.49,0-4.5-2.01-4.5-4.5
				c0-2.48,2.01-4.5,4.5-4.5H387.83z"/>
                      <path fill="#FFCC73" d="M383.33,52.68v30.97c0,3.79-3.09,6.88-6.88,6.88H260.83c-3.79,0-6.88-3.09-6.88-6.88V52.68
				c0-3.79,3.09-6.88,6.88-6.88h115.62C380.24,45.8,383.33,48.89,383.33,52.68z"/>
                      <path d="M345.84,324.87v15.06c0,5.84-4.75,10.59-10.59,10.59h-33.22c-5.84,0-10.59-4.75-10.59-10.59v-15.06
				c0-5.83,4.75-10.58,10.59-10.58h33.22C341.09,314.29,345.84,319.04,345.84,324.87z M336.84,339.93v-15.06
				c0-0.87-0.72-1.58-1.59-1.58h-33.22c-0.88,0-1.59,0.71-1.59,1.58v15.06c0,0.88,0.71,1.59,1.59,1.59h33.22
				C336.12,341.52,336.84,340.81,336.84,339.93z"/>
                      <path d="M345.84,276.3v15.06c0,5.83-4.75,10.58-10.59,10.58h-33.22c-5.84,0-10.59-4.75-10.59-10.58V276.3
				c0-5.84,4.75-10.59,10.59-10.59h33.22C341.09,265.71,345.84,270.46,345.84,276.3z M336.84,291.36V276.3
				c0-0.88-0.72-1.59-1.59-1.59h-33.22c-0.88,0-1.59,0.71-1.59,1.59v15.06c0,0.87,0.71,1.58,1.59,1.58h33.22
				C336.12,292.94,336.84,292.23,336.84,291.36z"/>
                      <path d="M345.84,226.98v15.06c0,5.84-4.75,10.59-10.59,10.59h-33.22c-5.84,0-10.59-4.75-10.59-10.59v-15.06
				c0-5.84,4.75-10.59,10.59-10.59h33.22C341.09,216.39,345.84,221.14,345.84,226.98z M336.84,242.04v-15.06
				c0-0.87-0.72-1.59-1.59-1.59h-33.22c-0.88,0-1.59,0.72-1.59,1.59v15.06c0,0.88,0.71,1.59,1.59,1.59h33.22
				C336.12,243.63,336.84,242.92,336.84,242.04z"/>
                      <path fill="#6BCE95" d="M336.84,324.87v15.06c0,0.88-0.72,1.59-1.59,1.59h-33.22c-0.88,0-1.59-0.71-1.59-1.59v-15.06
				c0-0.87,0.71-1.58,1.59-1.58h33.22C336.12,323.29,336.84,324,336.84,324.87z"/>
                      <path fill="#6BCE95" d="M336.84,276.3v15.06c0,0.87-0.72,1.58-1.59,1.58h-33.22c-0.88,0-1.59-0.71-1.59-1.58V276.3
				c0-0.88,0.71-1.59,1.59-1.59h33.22C336.12,274.71,336.84,275.42,336.84,276.3z"/>
                      <path fill="#6BCE95" d="M336.84,226.98v15.06c0,0.88-0.72,1.59-1.59,1.59h-33.22c-0.88,0-1.59-0.71-1.59-1.59v-15.06
				c0-0.87,0.71-1.59,1.59-1.59h33.22C336.12,225.39,336.84,226.11,336.84,226.98z"/>
                      <path d="M282.42,324.87v15.06c0,5.84-4.75,10.59-10.59,10.59h-33.22c-5.84,0-10.59-4.75-10.59-10.59v-15.06
				c0-5.83,4.75-10.58,10.59-10.58h33.22C277.67,314.29,282.42,319.04,282.42,324.87z M273.42,339.93v-15.06
				c0-0.87-0.71-1.58-1.59-1.58h-33.22c-0.88,0-1.59,0.71-1.59,1.58v15.06c0,0.88,0.71,1.59,1.59,1.59h33.22
				C272.71,341.52,273.42,340.81,273.42,339.93z"/>
                      <path d="M282.42,276.3v15.06c0,5.83-4.75,10.58-10.59,10.58h-33.22c-5.84,0-10.59-4.75-10.59-10.58V276.3
				c0-5.84,4.75-10.59,10.59-10.59h33.22C277.67,265.71,282.42,270.46,282.42,276.3z M273.42,291.36V276.3
				c0-0.88-0.71-1.59-1.59-1.59h-33.22c-0.88,0-1.59,0.71-1.59,1.59v15.06c0,0.87,0.71,1.58,1.59,1.58h33.22
				C272.71,292.94,273.42,292.23,273.42,291.36z"/>
                      <path d="M282.42,226.98v15.06c0,5.84-4.75,10.59-10.59,10.59h-33.22c-5.84,0-10.59-4.75-10.59-10.59v-15.06
				c0-5.84,4.75-10.59,10.59-10.59h33.22C277.67,216.39,282.42,221.14,282.42,226.98z M273.42,242.04v-15.06
				c0-0.87-0.71-1.59-1.59-1.59h-33.22c-0.88,0-1.59,0.72-1.59,1.59v15.06c0,0.88,0.71,1.59,1.59,1.59h33.22
				C272.71,243.63,273.42,242.92,273.42,242.04z"/>
                      <path fill="#6BCE95" d="M273.42,324.87v15.06c0,0.88-0.71,1.59-1.59,1.59h-33.22c-0.88,0-1.59-0.71-1.59-1.59v-15.06
				c0-0.87,0.71-1.58,1.59-1.58h33.22C272.71,323.29,273.42,324,273.42,324.87z"/>
                      <path fill="#6BCE95" d="M273.42,276.3v15.06c0,0.87-0.71,1.58-1.59,1.58h-33.22c-0.88,0-1.59-0.71-1.59-1.58V276.3
				c0-0.88,0.71-1.59,1.59-1.59h33.22C272.71,274.71,273.42,275.42,273.42,276.3z"/>
                      <path fill="#6BCE95" d="M273.42,226.98v15.06c0,0.88-0.71,1.59-1.59,1.59h-33.22c-0.88,0-1.59-0.71-1.59-1.59v-15.06
				c0-0.87,0.71-1.59,1.59-1.59h33.22C272.71,225.39,273.42,226.11,273.42,226.98z"/>
                      <path d="M267.85,107.46c2.49,0,4.5,2.02,4.5,4.5c0,2.49-2.01,4.5-4.5,4.5h-18.4c-2.48,0-4.5-2.01-4.5-4.5
				c0-2.48,2.02-4.5,4.5-4.5H267.85z"/>
                      <path d="M267.85,128.32c2.49,0,4.5,2.01,4.5,4.5c0,2.48-2.01,4.5-4.5,4.5h-18.4c-2.48,0-4.5-2.02-4.5-4.5
				c0-2.49,2.02-4.5,4.5-4.5H267.85z"/>
                      <path d="M267.85,149.17c2.49,0,4.5,2.01,4.5,4.5s-2.01,4.5-4.5,4.5h-18.4c-2.48,0-4.5-2.01-4.5-4.5s2.02-4.5,4.5-4.5H267.85z"/>
                      <path d="M267.85,170.02c2.49,0,4.5,2.02,4.5,4.5c0,2.49-2.01,4.5-4.5,4.5h-18.4c-2.48,0-4.5-2.01-4.5-4.5
				c0-2.48,2.02-4.5,4.5-4.5H267.85z"/>
                      <path fill="#3B75A8" d="M163.4,48.27v294.46c0,11.51-9.36,20.87-20.87,20.87H91.7c-11.51,0-20.87-9.36-20.87-20.87V48.27
				c0-11.51,9.36-20.87,20.87-20.87h50.83C154.04,27.4,163.4,36.76,163.4,48.27z"/>
                  </g>
              </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
      </svg>
  );
}

export default SvgComponent;
