import {Token} from "../model/token";
import {RatingSelect} from "../model/report";

export function setRatingSelect(select: RatingSelect) {
    localStorage.setItem("ratingSelect", JSON.stringify(select))
}

export function getRatingSelect(): RatingSelect | null {
    const value = localStorage.getItem("ratingSelect")
    return  !!value ? JSON.parse(value) : null
}
