import {
    Container,
    Grid,
    Box,
    Typography,
    Stack,
    Link as MuiLink, TextField,
} from '@mui/material';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {Link, useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';
import {useFormik} from "formik";
import {authService} from "../../../api/services/auth";
import {SET_USER} from "../../../redux/reducers/auth";
import {setLSToken} from "../../../utils/token";
import {Authority} from "../../../utils/common";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";
import { useSnack } from "../../../utils/useSnack";
import LoginImg from "../../../assets/img/login.jpg";


// 👇 Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// 👇 Styled Material UI Link Component
export const OauthMuiLink = styled(MuiLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 1;
  padding: 0.6rem 0;
  column-gap: 1rem;
  text-decoration: none;
  color: #393e45;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
  }
`;

export default function Login() {

    const { t } = useTranslation();
    const validationSchema = yup.object({
        email: yup
            .string()
            .trim()
            .email(t("validation.email_format"))
            .required(t("validation.email_required")),
        password: yup
            .string()
            .required(t("validation.password_required"))
    });

    const { snack } = useSnack();
    const navigate = useNavigate();
    const initialValues = { email: "", password: "" };
    const dispatch = useDispatch();

    async function onSubmit({ email, password }: {
        email: string; password: string
    }) {
        try {
            const response = await authService.login({ email, password });

            if (response.status === "success" && response.data) {
                const { access_token, refresh_token, token_type, profile } = response.data;
                setLSToken({ access_token, refresh_token, token_type });

                dispatch({ type: SET_USER, payload: profile });

                switch(profile.authorities[profile.authorities.length - 1]) {
                    case Authority.USER: return navigate("/profile")
                    case Authority.ADMIN: return navigate("/admin")

                    default: return
                }
            }
            else { snack("snack.error.login", "error") }
        }
        catch (error) {
            const axiosError = error as AxiosError<any>
            if (axiosError.response?.status === 401 && axiosError.response?.data?.code === "bad_credentials") {
                snack(t("snack.error.email_or_password"), "error")
            }
            console.error("ERROR", axiosError.response);
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });

    return (
        <Container
            maxWidth={false}
            sx={{ height: '100vh', backgroundColor: { xs: '#fff', md: '#f4f4f4' } }}>
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                sx={{ width: '100%', height: '100%' }}>
                <Grid
                    item
                    sx={{ maxWidth: '70rem', width: '100%', backgroundColor: '#fff' }}>

                        <Grid
                            container
                            sx={{
                                boxShadow: { sm: '0 0 5px #ddd' },
                                py: '6rem',
                                px: '1rem',
                            }}
                        >
                            <Grid
                                item
                                container
                                justifyContent='space-between'
                                rowSpacing={5}
                                sx={{
                                    maxWidth: { sm: '45rem' },
                                    marginInline: 'auto',
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{ borderRight: { sm: '1px solid #ddd' } }}
                                >

                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        component='form'
                                        noValidate
                                        autoComplete='off'
                                        sx={{ paddingRight: { sm: '3rem' } }}
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            formik.handleSubmit()
                                        }}
                                    >
                                    {/*<form onSubmit={formik.handleSubmit}>*/}
                                        <Typography
                                            variant='h6'
                                            component='h1'
                                            sx={{ textAlign: 'center', mb: '1.5rem' }}
                                        >
                                            <>{t('login_page.auth_to_enter')}</>
                                        </Typography>

                                        <CssTextField
                                            label="Email *"
                                            variant="outlined"
                                            name={'email'}
                                            fullWidth
                                            sx={{ mb: '1.5rem' }}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={!!formik.touched.email && !!formik.errors.email}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />

                                        <CssTextField
                                            label={`${t("inputs.password")} *`}
                                            name={'password'}
                                            type={'password'}
                                            fullWidth
                                            variant='outlined'
                                            sx={{ mb: '1.5rem' }}
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={!!formik.touched.password && !!formik.errors.password}
                                            helperText={formik.touched.password && formik.errors.password}
                                        />

                                        <LoadingButton
                                            loading={false}
                                            type='submit'
                                            variant='contained'
                                            sx={{
                                                py: '0.8rem',
                                                mt: 2,
                                                width: '80%',
                                                marginInline: 'auto',
                                            }}
                                        >
                                            <>{t('login_page.login')}</>
                                        </LoadingButton>
                                    </Box>
                                    {/*</form>*/}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        sx={{ paddingLeft: { sm: '3rem' }, rowGap: '1rem' }}>
                                        <img src={LoginImg} style={{maxHeight: "360px"}}/>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='center'>
                                <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
                                    <Typography sx={{ fontSize: '0.9rem' }}>
                                        <LinkItem to='/forgotPassword'>{`${t('login_page.forgot_password')}`}</LinkItem>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
