import { Box, Button, Grid, Typography } from "@mui/material";
import {useState, useEffect} from "react";
import {dictionaryService} from "../../../api/services/dictionary";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSnack} from "../../../utils/useSnack";
import {DataGrid} from "@mui/x-data-grid";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import Container from "../../../common/Container";
import { Column, columnStyle } from "../../../common/Columns";
import FullScreenDialog from "../../../components/Modal/FullScreenDialog";
import { default as Form } from './component'
import { useConfirm } from "material-ui-confirm";
import {reportService} from "../../../api/services/report";
import {ReportData} from "../../../model/report";
import { useDispatch } from "react-redux";
import {SET_USER} from "../../../redux/reducers/auth";
import {SET_REPORT} from "../../../redux/reducers/report";
import Link from "@mui/material/Link";

export default function Main() {

    const { t } = useTranslation();
    const { snack } = useSnack();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('');

    const [reports, setReports] = useState<ReportData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const resetValue: ReportData = {id: null, nameKz: '', nameRu: '', year: null,
        periodId: null, periodNameKz: '', periodNameRu: '', createdBy: '', createdAt: '', updatedBy: null, updatedAt: null}
    const [initialValues, setInitialValues] = useState<ReportData>(resetValue)

    const [row, setRow] = useState<ReportData | null>(null)
    const [readOnly, setReadOnly] = useState(false)

    const onPageChange = (newPage: number) => setPage(newPage);
    const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};
    const confirm = useConfirm();
    const dispatch = useDispatch()

    const handleRemoveClick = (report: ReportData ) => {
        confirm({ description: t("modals.common.remove_confirm") as string, title: '',
            cancellationText: t("modals.common.cancel") as string, confirmationText: t("modals.common.yes") as string })
            .then( async () => {
                try {
                    const response = await reportService.removeReport(report.id!!);
                    if (response.status === "success") {
                        getReports()
                    }
                } catch (error) {
                    snack(t("snack.error.announcements_registry"), "error");
                } finally {
                    setInitialValues(resetValue)
                    setRow(null)
                }
            })
    };

    async function getReports() {
        try {
            setLoading(true);
            const response = await reportService.getReports(page, pageSize);

            if (response.status === "success" && response.data) {
                setReports(response.data.data);
                setTotalElements(response.data.totalElements);
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {
            setLoading(false);
        }
    }

    async function getRecalc(id: number) {
        try {
            setLoading(true);
            const response = await reportService.getRecalc(id, page, pageSize);

            if (response.status === "success" && response.data) {
                setReports(response.data.data);
                setTotalElements(response.data.totalElements);
                snack(t("snack.success.action"), "success");
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {
            setLoading(false);
        }
    }

    async function onSubmit(report: ReportData) {
        try {
            const response = !report.id ? await reportService.createReport(report) :
                await reportService.updateReport(report)

            if (response.status === "success" && response.data) {
                getReports()
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {
            setOpen(false)
        }
    }

    useEffect(() => {
        getReports()
    }, [page, pageSize]);

    return (
        <Box>
            <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
                <Box marginBottom={4}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontWeight: 600,
                        }}>
                        <>{t("pages.reports")}</>
                    </Typography>
                </Box>

                <Box>
                    <Link
                        component="a"
                        style={{cursor: 'pointer'}}
                        onClick={(e) => {
                        e.preventDefault()
                        reportService.downloadMethod()
                    }}>
                        <>{t("pages.admin.dictionary.buttons.downloadMethod")}</>
                    </Link>
                </Box>

                <Grid item container paddingY={{ xs: 2, sm: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(false)
                                    setTitle(t("pages.admin.dictionary.dialog.title_add"))
                                    setInitialValues(resetValue)
                                    setOpen(true)
                                }}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.add")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(true)
                                    setTitle(t("pages.admin.dictionary.dialog.title_view") + row?.id)
                                    setInitialValues(row!!)
                                    setOpen(true)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.view")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(false)
                                    setTitle(t("pages.admin.dictionary.dialog.title_update") + row?.id)
                                    setInitialValues(row!!)
                                    setOpen(true)

                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.update")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setInitialValues(row!!)
                                    handleRemoveClick(row!!)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.remove")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setInitialValues(row!!)
                                    dispatch({ type: SET_REPORT, payload: row });
                                    getRecalc(row!!.id || 0)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.calc")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setInitialValues(row!!)
                                    dispatch({ type: SET_REPORT, payload: row });
                                    navigate(`/reports/${row!!.id}`)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.go")}</>
							</span>
                        </Button>
                    </Grid>
                </Grid>

                <Box>
                    <DataGrid
                        components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
                        sx={{
                            height: 500,
                            "& .MuiDataGrid-cell": {
                                whiteSpace: "pre-wrap"
                            },
                        }}
                        columnBuffer={3}
                        rows={reports}
                        columns={[
                            Column("pages.admin.dictionary.table.id", "id", 50, columnStyle, t),
                            Column("pages.admin.dictionary.table.nameKz", "nameKz", 300, columnStyle, t),
                            Column("pages.admin.dictionary.table.nameRu", "nameRu", 300, columnStyle, t),
                            Column("pages.admin.dictionary.table.year", "year", 60, columnStyle, t),
                            Column("pages.admin.dictionary.table.periodNameKz", "periodNameKz", 300, columnStyle, t),
                            Column("pages.admin.dictionary.table.periodNameRu", "periodNameRu", 300, columnStyle, t),
                            Column("pages.admin.dictionary.table.createdBy", "createdBy", 200, columnStyle, t),
                            Column("pages.admin.dictionary.table.createdAt", "createdAt", 200, columnStyle, t),
                            Column("pages.admin.dictionary.table.updatedBy", "updatedBy", 200, columnStyle, t),
                            Column("pages.admin.dictionary.table.updatedAt", "updatedAt", 200, columnStyle, t),
                        ]}
                        onRowClick={(rowData) => setRow(rowData.row)}
                        page={page} onPageChange={onPageChange}
                        pageSize={pageSize} onPageSizeChange={onPageSizeChange}
                        rowCount={totalElements} rowsPerPageOptions={[5, 10, 15]}
                        loading={loading} paginationMode="server"
                        density="comfortable" style={{ whiteSpace: "pre-wrap" }}
                    />
                </Box>
            </Container>

            <FullScreenDialog
                open={open}
                setOpen={setOpen}
                form={<Form onSubmit={onSubmit} initialValues={initialValues} readOnly={readOnly}/>}
                title={title}
                t={t}/>
        </Box>
    )
}
