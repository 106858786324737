function stringToDate(value: string | null): Date | null {
    if(!value) {
        return null
    }
    const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    return  new Date(value!.replace(pattern,'$3-$2-$1'));
}

function dateToString(value: any): string | null {
    console.log("value 1 ------ ", value)
    if(!value) {
        return null
    }
    const { $D, $M, $y } = value
    return  `${String($D).padStart(2, '0')}.${String($M + 1).padStart(2, '0')}.${$y}`;
}


export const dateUtil = {
    stringToDate,
    dateToString
}
