import { AxiosResponse } from "axios";
import { Response } from "../../model/api";
import { LoginData, SignupFirstStepData, SignupFinalStepData,
    ForgotPasswordFirstStepData,
    User} from "../../model/auth";
import { LoginResponseData, Token } from "../../model/token";
import protectedInstance from "../instances/protected";
import publicInstance from "../instances/public";

async function signupFirstStep(data: SignupFirstStepData, recaptchaToken: string) {
    return publicInstance
        .post(`/user/register/${recaptchaToken}`, data)
        .then((res: AxiosResponse<Response<{ id: string }>>) => res.data)
}

async function signupFinalStep(data: SignupFinalStepData, actionId: string) {
    return publicInstance
        .post(`/approve/${actionId}`, data)
        .then((res: AxiosResponse<Response<null>>) => res.data)
}

async function forgotPasswordFirstStep(data: ForgotPasswordFirstStepData, recaptchaToken: string) {
    return publicInstance
        .post(`/user/restore/${recaptchaToken}`, data)
        .then((res: AxiosResponse<Response<{ id: string }>>) => res.data)
}

const forgotPasswordFinalStep = signupFinalStep;

async function login(data: LoginData) {
    return publicInstance
        .post("/login", data)
        .then((res: AxiosResponse<Response<LoginResponseData>>) => res.data)
}

async function refreshToken(data: { refreshToken: string }) {
    return publicInstance
        .post("/token/refresh", data)
        .then((res: AxiosResponse<Response<Token>>) => res.data)
}

async function getUser() {
    return protectedInstance
        .get("/me")
        .then((res: AxiosResponse<Response<User>>) => res.data)
}

export const authService = {
    forgotPasswordFinalStep,
    login,
    refreshToken,
    getUser
};
