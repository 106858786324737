import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';
import { Pages } from '../../../model/pages';

const Sidebar = ({
                     pages, open, variant, onClose, ...rest
                 }: {
    pages: Pages;
    open: boolean;
    variant?: 'permanent' | 'persistent' | 'temporary';
    onClose: () => void;
}) => {
    return (
        <Drawer
            anchor="left"
            onClose={() => onClose()}
            open={open}
            variant={variant}
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: { xs: '100%', sm: 400 },
                },
            }}
        >
            <Box
                {...rest}
                sx={{
                    height: '100%',
                    padding: 1,
                }}
            >
                <SidebarNav pages={pages} onClose={onClose} />
            </Box>
        </Drawer>
    );
};

Sidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
    pages: PropTypes.array.isRequired,
};

export default Sidebar
