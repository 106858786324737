import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../../components/Elements/BlogBox";
import FullButton from "../../../components/Buttons/FullButton";
import TestimonialSlider from "../../../components/Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      {/*<div className="whiteBg">*/}
      {/*  <div className="container">*/}
      {/*    <HeaderInfo>*/}
      {/*      <h1 className="font40 extraBold">Our Blog Stories</h1>*/}
      {/*      <p className="font13">*/}
      {/*        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
      {/*        <br />*/}
      {/*        labore et dolore magna aliquyam erat, sed diam voluptua.*/}
      {/*      </p>*/}
      {/*    </HeaderInfo>*/}
      {/*    <div className="row textCenter">*/}
      {/*      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
      {/*        <BlogBox*/}
      {/*          title="New Office!"*/}
      {/*          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
      {/*          tag="company"*/}
      {/*          author="Luke Skywalker, 2 days ago"*/}
      {/*          action={() => alert("clicked")}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
      {/*        <BlogBox*/}
      {/*          title="New Office!"*/}
      {/*          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
      {/*          tag="company"*/}
      {/*          author="Luke Skywalker, 2 days ago"*/}
      {/*          action={() => alert("clicked")}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
      {/*        <BlogBox*/}
      {/*          title="New Office!"*/}
      {/*          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
      {/*          tag="company"*/}
      {/*          author="Luke Skywalker, 2 days ago"*/}
      {/*          action={() => alert("clicked")}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row textCenter">*/}
      {/*      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
      {/*        <BlogBox*/}
      {/*          title="New Office!"*/}
      {/*          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
      {/*          tag="company"*/}
      {/*          author="Luke Skywalker, 2 days ago"*/}
      {/*          action={() => alert("clicked")}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
      {/*        <BlogBox*/}
      {/*          title="New Office!"*/}
      {/*          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
      {/*          tag="company"*/}
      {/*          author="Luke Skywalker, 2 days ago"*/}
      {/*          action={() => alert("clicked")}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
      {/*        <BlogBox*/}
      {/*          title="New Office!"*/}
      {/*          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
      {/*          tag="company"*/}
      {/*          author="Luke Skywalker, 2 days ago"*/}
      {/*          action={() => alert("clicked")}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row flexCenter">*/}
      {/*      <div style={{ margin: "50px 0", width: "200px" }}>*/}
      {/*        <FullButton title="Load More" action={() => alert("clicked")} border={false} />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            {/*<h1 className="font40 extraBold">"</h1>*/}
            <p className="font13">
              Касым-Жомарт Токаев назвал важным событием создание Алматинской области в качестве отдельной территориальной
              <br />
              единицы и перенос административного центра в город Қонаев:
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
