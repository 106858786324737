import { Box, Button, Grid, Typography } from "@mui/material";
import {useState, useEffect} from "react";
import {DictionaryData, PeriodData} from "../../../model/dictionary";
import {dictionaryService} from "../../../api/services/dictionary";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSnack} from "../../../utils/useSnack";
import {DataGrid} from "@mui/x-data-grid";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import Container from "../../../common/Container";
import { Column, columnStyle } from "../../../common/Columns";
import FullScreenDialog from "../../../components/Modal/FullScreenDialog";
import { default as Form } from './component'
import { useConfirm } from "material-ui-confirm";

export default function Categories() {

    const { t } = useTranslation();
    const { snack } = useSnack();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('');

    const [categories, setCategories] = useState<DictionaryData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const resetValue: DictionaryData = {id: null, code: '', nameKz: '', nameRu: '', dateEntry: null, status: null}
    const [initialValues, setInitialValues] = useState<DictionaryData>(resetValue)

    const [row, setRow] = useState<DictionaryData | null>(null)
    const [readOnly, setReadOnly] = useState(false)

    const onPageChange = (newPage: number) => setPage(newPage);
    const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};
    const confirm = useConfirm();

    const handleRemoveClick = (category: DictionaryData ) => {
        confirm({ description: t("modals.common.remove_confirm") as string, title: '',
            cancellationText: t("modals.common.cancel") as string, confirmationText: t("modals.common.yes") as string })
            .then( async () => {
                try {
                    const response = await dictionaryService.removeCategory(category.id!!);
                    if (response.status === "success") {
                        getCategories()
                    }
                } catch (error) {
                    snack(t("snack.error.announcements_registry"), "error");
                } finally {
                    setInitialValues(resetValue)
                    setRow(null)
                }
            })
    };

    async function getCategories() {
        try {
            setLoading(true);
            const response = await dictionaryService.getCategories(page, pageSize);

            if (response.status === "success" && response.data) {
                setCategories(response.data.data);
                setTotalElements(response.data.totalElements);
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {
            setLoading(false);
        }
    }

    async function onSubmit(category: DictionaryData) {
        try {
            const response = !category.id ? await dictionaryService.createCategory(category) :
                await dictionaryService.updateCategory(category)

            if (response.status === "success" && response.data) {
                getCategories()
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {
            setOpen(false)
        }
    }

    useEffect(() => {
        getCategories()
    }, [page, pageSize]);

    return (
        <Box>
            <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
                <Box marginBottom={4}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontWeight: 600,
                        }}>
                        <>{t("pages.admin.dictionary.category")}</>
                    </Typography>
                </Box>

                <Grid item container paddingY={{ xs: 2, sm: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={3} md={3}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(false)
                                    setTitle(t("pages.admin.dictionary.dialog.title_add"))
                                    setInitialValues(resetValue)
                                    setOpen(true)
                                }}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.add")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(true)
                                    setTitle(t("pages.admin.dictionary.dialog.title_view") + row?.id)
                                    setInitialValues(row!!)
                                    setOpen(true)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.view")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setReadOnly(false)
                                    setTitle(t("pages.admin.dictionary.dialog.title_update") + row?.id)
                                    setInitialValues(row!!)
                                    setOpen(true)

                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.update")}</>
							</span>
                        </Button>
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => {
                                    setInitialValues(row!!)
                                    handleRemoveClick(row!!)
                                }} disabled={!row}>
							<span style={{ whiteSpace: 'nowrap' }}>
								 <>{t("pages.admin.dictionary.buttons.remove")}</>
							</span>
                        </Button>
                    </Grid>
                </Grid>

                <Box>
                    <DataGrid
                        components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
                        sx={{
                            height: 500,
                            "& .MuiDataGrid-cell": {
                                whiteSpace: "pre-wrap"
                            },
                        }}
                        columnBuffer={3}
                        rows={categories}
                        columns={[
                            Column("pages.admin.dictionary.table.id", "id", 50, columnStyle, t),
                            Column("pages.admin.dictionary.table.code", "code", 100, columnStyle, t),
                            Column("pages.admin.dictionary.table.nameKz", "nameKz", 300, columnStyle, t),
                            Column("pages.admin.dictionary.table.nameRu", "nameRu", 300, columnStyle, t),
                            Column("pages.admin.dictionary.table.dateEntry", "dateEntry", 200, columnStyle, t),
                        ]}
                        onRowClick={(rowData) => setRow(rowData.row)}
                        page={page} onPageChange={onPageChange}
                        pageSize={pageSize} onPageSizeChange={onPageSizeChange}
                        rowCount={totalElements} rowsPerPageOptions={[5, 10, 15]}
                        loading={loading} paginationMode="server"
                        density="comfortable" style={{ whiteSpace: "pre-wrap" }}
                    />
                </Box>
            </Container>

            <FullScreenDialog
                open={open}
                setOpen={setOpen}
                form={<Form onSubmit={onSubmit} initialValues={initialValues} readOnly={readOnly}/>}
                title={title}
                t={t}/>
        </Box>
    )
}
