import { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "../api/services/auth";
import { SET_USER } from "../redux/reducers/auth";
import { User } from "../model/auth";
import { DecodedToken } from "../model/token";
import { Authority } from "./common";
import { getLSToken } from "./token";

export function useAuth() {
    const token = getLSToken();
    const decoded: DecodedToken | null = token ? jwtDecode(token.access_token) : null;
    const location = useLocation();
    const navigate = useNavigate();

    function roleNavigation() {
        if(!token && (location.pathname === '/' ||  (location.pathname === '/login'))) {
            navigate(location.pathname);
        }
        if (!decoded) {
            return
        }
        switch (decoded.authorities[decoded.authorities.length - 1]) {
            case Authority.USER:
                if (location.pathname.includes("/report")) {
                    navigate(location.pathname)
                }
                break;
            case Authority.ADMIN:
                if (location.pathname.includes("/report") ||  location.pathname.includes("/dictionary") || location.pathname.includes("/admin")) {
                    navigate(location.pathname)
                }
                break;

            default:
                navigate("/")
        }
    }

    const dispatch = useDispatch();
    const [user, setUser] = useState<User | null>(null);

    async function getUser() {
        try {
            if (token && !user) {
                    const response = await authService.getUser();
                    if (response.status === "success" && response.data) {
                        dispatch({type: SET_USER, payload: response.data});
                        setUser(response.data);
                    }

            }
        } catch (error) {
            const err = error as AxiosError;
            console.error("ERROR", err.response);
        }
    }

    useEffect(() => { getUser(); }, []);
    useEffect(() => { roleNavigation(); }, [location.pathname]);

    return { user }
}
