import React from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";


const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    width: 376,
    height: 309,
    backgroundColor: "rgba(24, 108, 188, 0.7) !important",
    lineHeight: '60px',

}));

const ItemTitle = styled.div`
    color: white;
    font-weight: 700;
    font-size: 30px;
     position: relative;
     top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
`

export default function ProjectBox({ img, title, territory, population, county, settlements, text, action, show = true}:
{img: string, title: string, territory: string, population: string, county: string, settlements: string, text: string, action: Function | null, show?: boolean}) {
  return (
    <Wrapper>
      <ImgBtn className="animate pointer" onClick={() => action ? action() : null}>
        <img style={{maxHeight: '250px', minHeight: '250px', maxWidth: '300px'}} className="radius8" src={img} alt="project"></img>
      </ImgBtn>
      <div className="font13 dist-box-desc">
          <div style={{textAlign: "center"}}>
              {title}
          </div>
          { show &&
              <>
                  <TableRow>
                      <LabelRow>Территория района –</LabelRow>
                      <ValueRow>{territory}</ValueRow>
                  </TableRow>
                  <TableRow>
                      <LabelRow>Численность населения –</LabelRow>
                      <ValueRow>{population}</ValueRow>
                  </TableRow>
                  <TableRow>
                      <LabelRow>Количество сельских округов – </LabelRow>
                      <ValueRow>{county}</ValueRow>
                  </TableRow>
                  <TableRow>
                      <LabelRow>Количество населенных пунктов – </LabelRow>
                      <ValueRow>{settlements}</ValueRow>
                  </TableRow>
                  <TableRow>
                      <LabelRow>Территория района –</LabelRow>
                      <ValueRow>{territory}</ValueRow>
                  </TableRow>
                <p className="font13 dist-box-desc" style={{fontWeight: "normal"}}>{text}</p>
            </>
      }
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;


const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LabelRow = styled.label`
  flex: 0 0 auto;
  width: 70%;
`;

const ValueRow = styled.div`
  flex: 0 0 auto;
  width: 30%;
  font-weight: normal;
`;
