import * as React from "react";

function SvgComponent(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"   width={39.581} height={39.58} viewBox="0 0 39.581 39.58" {...props}>
        <path fill="#dbf2ff" d="M3.5 38.5L3.5 13.286 19.998 3.58 36.5 13.286 36.5 38.5z"/>
        <path fill="#7496c4" d="M19.998,4.16L36,13.572V38H4V13.572L19.998,4.16 M19.998,3L3,13v26h34V13L19.998,3L19.998,3z"/>
        <path fill="#b5ddf5" d="M4 34H36V38H4z"/>
        <path fill="#f78f8f" d="M19.998 4.645L1.5 15.955 1.5 12.896 19.998 1.586 38.5 12.896 38.5 15.955z"/>
        <path fill="#c74343" d="M19.998,2.172L38,13.176v1.887L20.519,4.378l-0.522-0.319l-0.522,0.319L2,15.063v-1.887 L19.998,2.172 M19.998,1L1,12.615v4.231L19.998,5.231L39,16.846v-4.231L19.998,1L19.998,1z"/>
        <g>
          <path fill="#ffc49c" d="M14.5 21.5H25.5V38.5H14.5z"/>
          <path fill="#a16a4a" d="M25,22v16H15V22H25 M26,21H14v18h12V21L26,21z"/>
        </g>
        <path fill="#a16a4a" d="M23 30A1 1 0 1 0 23 32A1 1 0 1 0 23 30Z"/>
      </svg>
  );
}

export default SvgComponent;
