import protectedInstance from "../instances/protected";
import {AxiosResponse} from "axios";
import {PaginationResponse, Response} from "../../model/api";
import {
    DictionaryData,
    DictionaryObject, IndicatorData,
    KatoData,
    PeriodData,
    SubCategoryData,
    SubFactorData
} from "../../model/dictionary";


async function getPeriods(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/periods?${query}`)
        .then((res: AxiosResponse<PaginationResponse<PeriodData[]>>) => res.data)
}

async function createPeriod(period: PeriodData) {
    return protectedInstance
        .post(`/nsi/periods`, period)
        .then((res: AxiosResponse<Response<PeriodData>>) => res.data)
}

async function updatePeriod(period: PeriodData) {
    return protectedInstance
        .put(`/nsi/periods`, period)
        .then((res: AxiosResponse<Response<PeriodData>>) => res.data)
}

async function removePeriod(id: number) {
    return protectedInstance
        .delete(`/nsi/periods/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}


async function getFactors(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/factors?${query}`)
        .then((res: AxiosResponse<PaginationResponse<DictionaryData[]>>) => res.data)
}


async function createFactor(factor: DictionaryData) {
    return protectedInstance
        .post(`/nsi/factors`, factor)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function updateFactor(factor: DictionaryData) {
    return protectedInstance
        .put(`/nsi/factors`, factor)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function removeFactor(id: number) {
    return protectedInstance
        .delete(`/nsi/factors/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getCategories(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/categories?${query}`)
        .then((res: AxiosResponse<PaginationResponse<DictionaryData[]>>) => res.data)
}

async function createCategory(category: DictionaryData) {
    return protectedInstance
        .post(`/nsi/categories`, category)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function updateCategory(category: DictionaryData) {
    return protectedInstance
        .put(`/nsi/categories`, category)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function removeCategory(id: number) {
    return protectedInstance
        .delete(`/nsi/categories/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getUnitMeasures(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/unit-measures?${query}`)
        .then((res: AxiosResponse<PaginationResponse<DictionaryData[]>>) => res.data)
}

async function createUnitMeasure(unitMeasure: DictionaryData) {
    return protectedInstance
        .post(`/nsi/unit-measures`, unitMeasure)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function updateUnitMeasure(unitMeasure: DictionaryData) {
    return protectedInstance
        .put(`/nsi/unit-measures`, unitMeasure)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function removeUnitMeasure(id: number) {
    return protectedInstance
        .delete(`/nsi/unit-measures/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getKatoList(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/kato?${query}`)
        .then((res: AxiosResponse<PaginationResponse<KatoData[]>>) => res.data)
}

async function createKato(kato: KatoData) {
    return protectedInstance
        .post(`/nsi/kato`, kato)
        .then((res: AxiosResponse<Response<KatoData>>) => res.data)
}

async function updateKato(kato: KatoData) {
    return protectedInstance
        .put(`/nsi/kato`, kato)
        .then((res: AxiosResponse<Response<KatoData>>) => res.data)
}

async function removeKato(id: number) {
    return protectedInstance
        .delete(`/nsi/kato/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getSubCategories(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/sub-categories?${query}`)
        .then((res: AxiosResponse<PaginationResponse<SubCategoryData[]>>) => res.data)
}

async function createSubCategory(subCategory: SubCategoryData) {
    return protectedInstance
        .post(`/nsi/sub-categories`, subCategory)
        .then((res: AxiosResponse<Response<SubCategoryData>>) => res.data)
}

async function updateSubCategory(subCategory: SubCategoryData) {
    return protectedInstance
        .put(`/nsi/sub-categories`, subCategory)
        .then((res: AxiosResponse<Response<SubCategoryData>>) => res.data)
}

async function removeSubCategory(id: number) {
    return protectedInstance
        .delete(`/nsi/sub-categories/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}


async function getSubFactors(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/sub-factors?${query}`)
        .then((res: AxiosResponse<PaginationResponse<SubFactorData[]>>) => res.data)
}

async function createSubFactor(subFactor: SubFactorData) {
    return protectedInstance
        .post(`/nsi/sub-factors`, subFactor)
        .then((res: AxiosResponse<Response<SubFactorData>>) => res.data)
}

async function updateSubFactor(subFactor: SubFactorData) {
    return protectedInstance
        .put(`/nsi/sub-factors`, subFactor)
        .then((res: AxiosResponse<Response<SubFactorData>>) => res.data)
}

async function removeSubFactor(id: number) {
    return protectedInstance
        .delete(`/nsi/sub-factors/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getDepartments(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/departments?${query}`)
        .then((res: AxiosResponse<PaginationResponse<DictionaryData[]>>) => res.data)
}

async function createDepartment(department: DictionaryData) {
    return protectedInstance
        .post(`/nsi/departments`, department)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function updateDepartment(department: DictionaryData) {
    return protectedInstance
        .put(`/nsi/departments`, department)
        .then((res: AxiosResponse<Response<DictionaryData>>) => res.data)
}

async function removeDepartment(id: number) {
    return protectedInstance
        .delete(`/nsi/departments/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getIndicators(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/nsi/indicators?${query}`)
        .then((res: AxiosResponse<PaginationResponse<IndicatorData[]>>) => res.data)
}

async function createIndicator(indicator: IndicatorData) {
    return protectedInstance
        .post(`/nsi/indicators`, indicator)
        .then((res: AxiosResponse<Response<IndicatorData>>) => res.data)
}

async function updateIndicator(indicator: IndicatorData) {
    return protectedInstance
        .put(`/nsi/indicators`, indicator)
        .then((res: AxiosResponse<Response<IndicatorData>>) => res.data)
}

async function removeIndicator(id: number) {
    return protectedInstance
        .delete(`/nsi/indicators/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getDictFactors() {
    return protectedInstance
        .get(`/nsi/factors/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictCategories() {
    return protectedInstance
        .get(`/nsi/categories/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictUnitMeasures() {
    return protectedInstance
        .get(`/nsi/unit-measures/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictPeriods() {
    return protectedInstance
        .get(`/nsi/periods/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictDepartments() {
    return protectedInstance
        .get(`/nsi/departments/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}


async function getDictSubFactors() {
    return protectedInstance
        .get(`/nsi/sub-factors/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictSubCategories() {
    return protectedInstance
        .get(`/nsi/sub-categories/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictSubFactorsByFactorId(factorId: number) {
    return protectedInstance
        .get(`/nsi/sub-factors/dict/${factorId}`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}


async function getDictSubCategoriesByCategoryId(categoryId: number) {
    return protectedInstance
        .get(`/nsi/sub-categories/dict/${categoryId}`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictIndicators() {
    return protectedInstance
        .get(`/nsi/indicators/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictIndicatorParents() {
    return protectedInstance
        .get(`/nsi/indicators/dict/parents`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictIndicatorChildren(id: number) {
    return protectedInstance
        .get(`/nsi/indicators/dict/parents/${id}`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

export const dictionaryService = {
    getPeriods,
    createPeriod,
    updatePeriod,
    removePeriod,
    getFactors,
    createFactor,
    updateFactor,
    removeFactor,
    getCategories,
    createCategory,
    updateCategory,
    removeCategory,
    getUnitMeasures,
    createUnitMeasure,
    updateUnitMeasure,
    removeUnitMeasure,
    getKatoList,
    createKato,
    updateKato,
    removeKato,
    getSubCategories,
    createSubCategory,
    updateSubCategory,
    removeSubCategory,
    getDictCategories,
    getSubFactors,
    createSubFactor,
    updateSubFactor,
    removeSubFactor,
    getDictFactors,
    getDepartments,
    createDepartment,
    updateDepartment,
    removeDepartment,
    getIndicators,
    createIndicator,
    updateIndicator,
    removeIndicator,
    getDictIndicators,
    getDictIndicatorParents,
    getDictIndicatorChildren,
    getDictUnitMeasures,
    getDictPeriods,
    getDictDepartments,
    getDictSubFactors,
    getDictSubFactorsByFactorId,
    getDictSubCategories,
    getDictSubCategoriesByCategoryId
};
