import React, {useState, ReactElement} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { Topbar, Sidebar, Footer } from '../components';
import Container from '../../common/Container';
import { pages } from '../navigation';
import {AppBar} from "@mui/material";

const Public = ({
                  children,
                  themeToggle,
                  themeMode,
                  setThemePalette,
                  paletteType,
              }: {
    children: ReactElement;
    themeToggle: () => void;
    themeMode: "light" | "dark";
    setThemePalette: (type?: string) => void;
    paletteType: string;
}) => {
    const theme = useTheme();
    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    return (
        <div>
                <AppBar
                    position={'relative'}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                    elevation={1}
                >
                <Topbar
                    onSidebarOpen={handleSidebarOpen}
                    themeMode={themeMode}
                    themeToggle={themeToggle}
                    setThemePalette={setThemePalette}
                    paletteType={paletteType}
                    removeLandingLinks={true}
                />
            </AppBar>
            <Sidebar
                onClose={handleSidebarClose}
                open={openSidebar}
                variant="temporary"
                pages={pages}
            />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    );
};

Public.propTypes = {
    children: PropTypes.node,
    themeToggle: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
    setThemePalette: PropTypes.func.isRequired,
    paletteType: PropTypes.string.isRequired,
};

export default Public;
