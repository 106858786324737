import * as React from "react";

function SvgComponent(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width={39.581} height={39.58} viewBox="0 0 39.581 39.58" {...props}>
          <path fill="#f5c276" d="M22.5 27.5H26.5V38.5H22.5z"/>
          <path fill="#c29653" d="M26,28v10h-3V28H26 M27,27h-5v12h5V27L27,27z"/>
          <path fill="#8bb7f0" d="M6.5,27.5V14c0-3.584,2.916-6.5,6.5-6.5h19c3.584,0,6.5,2.916,6.5,6.5v13.5H6.5z"/>
          <path fill="#4e7ab5" d="M32,8c3.308,0,6,2.692,6,6v13H7V14c0-3.308,2.692-6,6-6H32 M32,7H13c-3.85,0-7,3.15-7,7v14h33V14 C39,10.15,35.85,7,32,7L32,7z"/>
          <path fill="#8bb7f0" d="M6.5,27.5V13.853C6.5,10.409,9.477,7.5,13,7.5c3.584,0,6.5,2.916,6.5,6.5v13.5H6.5z"/>
          <path fill="#4e7ab5" d="M13,8c3.308,0,6,2.692,6,6v13H7V13.853C7,10.68,9.748,8,13,8 M13,7c-3.85,0-7,3.15-7,6.853V28h14 V14C20,10.15,16.85,7,13,7L13,7z"/>
          <path fill="#f78f8f" d="M32.5 12.5H35.5V17.5H32.5z"/><path fill="#c74343" d="M35,13v4h-2v-4H35 M36,12h-4v6h4V12L36,12z"/>
          <g><path fill="#f78f8f" d="M25 12H36V13H25z"/>
              <path fill="#c74343" d="M36 12L25 12 25 13 36 13 36 12z"/>
          </g>
          <g>
              <path fill="#f78f8f" d="M24.5 11.5A1 1 0 1 0 24.5 13.5A1 1 0 1 0 24.5 11.5Z"/>
              <path fill="#c74343" d="M24.5,12c0.276,0,0.5,0.224,0.5,0.5S24.776,13,24.5,13S24,12.776,24,12.5S24.224,12,24.5,12 M24.5,11c-0.828,0-1.5,0.672-1.5,1.5s0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5S25.328,11,24.5,11L24.5,11z"/>
          </g>
          <g>
              <path fill="#8bb7f0" d="M9 15H17V16H9z"/>
              <path fill="#4e7ab5" d="M17 15L9 15 9 16 17 16 17 15z"/>
          </g>
      </svg>
  );
}

export default SvgComponent;
