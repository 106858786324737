import i18next, {TOptions} from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: "ru",
        lng: "ru",
        debug: false,
        interpolation: {
            escapeValue: false
        },
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locale/{{lng}}/{{ns}}.json'
        }
    });

export default i18next;

export type i18translateType = {
    t: (key: string | TemplateStringsArray | (string | TemplateStringsArray)[], options?: string | TOptions<any> | undefined) => string
}
