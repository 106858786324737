import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {
    DictionaryObject,
    IndicatorData
} from "../../../../model/dictionary";
import {
    Autocomplete,
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from '@emotion/styled';
import {useEffect, useState} from "react";
import {dictionaryService} from "../../../../api/services/dictionary";

export default function Form({onSubmit, initialValues, readOnly}: {onSubmit: (e: IndicatorData) => void, initialValues: IndicatorData,
    readOnly: boolean}) {
    console.log('initialValues', initialValues)
    const { t } = useTranslation();
    const validationSchema = yup.object({
        // id: yup
        //     .number(),
        code: yup
            .string()
            .trim()
            .required(t("validation.required"))
            .max(5, t("validation.max_length") + ' 5'),
        nameKz: yup
            .string()
            .trim()
            .required(t("validation.required")),
        nameRu: yup
            .string()
            .trim()
            .required(t("validation.required")),
        unitMeasureId: yup
            .number()
            .nullable()
            .required(t("validation.required"))
            .transform((_, val) => val === Number(val) ? val : null),
        raise: yup
            .boolean()
            .nullable(true),
        agr: yup
            .string()
            .required(t("validation.required"))
    });
    const [unitMeasures, setUnitMeasures] = useState<DictionaryObject[]>([])
    const [parents, setParents] = useState<DictionaryObject[]>([])
    const [parent, setParent] = useState<{nameRu: string, nameKz: string, id: number | null} | null>({nameRu: initialValues.indicatorNameRu, nameKz: initialValues.indicatorNameKz, id: initialValues.parentId})
    async function getDictUnitMeasures() {
        try {
            const response = await dictionaryService.getDictUnitMeasures()
            if(response.status === 'success' && response.data) {
                setUnitMeasures(response.data)
            }
        } catch (ignored) {}
    }

    async function getParents() {
        try {
            const response = await dictionaryService.getDictIndicatorParents()
            if(response.status === 'success' && response.data) {
                setParents(response.data)
            }
        } catch (ignored) {}
    }
    useEffect(() => {
        getDictUnitMeasures()
        getParents()
    }, [])

    function convert(value: number | null): string {
        if(!value) {
            return ''
        }
        return `${value}`
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });
    return <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        sx={{ paddingRight: { sm: '3rem' } }}
        onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
        }}>

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.code")} *`}
            variant="outlined"
            name={'code'}
            fullWidth
            sx={{ mb: '1.5rem' }}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={!!formik.touched.code && !!formik.errors.code}
            helperText={formik.touched.code && formik.errors.code}
        />

        <FormControlLabel  sx={{ mb: '1.5rem' }} control={<Checkbox name={'raise'}
                                             checked={formik.values.raise || false}
                                             onChange={formik.handleChange}
                                             value={formik.values.raise}/>} label={`${t("pages.admin.dictionary.inputs.raise")}`}/>

        <FormControl>
            <InputLabel id="select-label-factor">{`${t("pages.admin.dictionary.selects.agr")} *`}</InputLabel>
            <Select
                labelId="select-label-factor"
                id="select-factor"
                name={"factorId"}
                label={`${t("pages.admin.dictionary.selects.agr")} *`}
                disabled={readOnly}
                sx={{ mb: '1.5rem' }}
                value={formik.values.agr}
                error={!!formik.touched.agr && !!formik.errors.agr}
                onChange={(value) => formik.setFieldValue("agr", value.target.value, true)}>
                <MenuItem value={'SUM'}>{'Сумма'}</MenuItem>
                <MenuItem value={'AVG'}>{'Средняя'}</MenuItem>
            </Select>
            <FormHelperText error={!!formik.touched.agr && !!formik.errors.agr}>{(formik.errors.agr && formik.touched.agr) && formik.errors.agr}</FormHelperText>
        </FormControl>

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameKz")} *`}
            name={'nameKz'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameKz}
            onChange={formik.handleChange}
            error={!!formik.touched.nameKz && !!formik.errors.nameKz}
            helperText={formik.touched.nameKz && formik.errors.nameKz}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameRu")} *`}
            name={'nameRu'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameRu}
            onChange={formik.handleChange}
            error={!!formik.touched.nameRu && !!formik.errors.nameRu}
            helperText={formik.touched.nameRu && formik.errors.nameRu}
        />
        <FormControl>
            <InputLabel id="select-label-unitMeasure">{`${t("pages.admin.dictionary.selects.unitMeasure")} *`}</InputLabel>
            <Select
                labelId="select-label-unitMeasure"
                id="select-unitMeasure"
                name={"unitMeasureId"}
                sx={{ mb: '1.5rem' }}
                disabled={readOnly}
                label={`${t("pages.admin.dictionary.selects.unitMeasure")} *`}
                value={convert(formik.values.unitMeasureId)}
                error={!!formik.touched.unitMeasureId && !!formik.errors.unitMeasureId}
                onChange={(value) => formik.setFieldValue("unitMeasureId", Number(value.target.value), true)}>
                <MenuItem key={''} value={''}>{' '}</MenuItem>
                {
                    unitMeasures.map((value, index) => (
                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                    ))
                }
                </Select>
            <FormHelperText error={!!formik.touched.unitMeasureId && !!formik.errors.unitMeasureId}>{(formik.errors.unitMeasureId && formik.touched.unitMeasureId) && formik.errors.unitMeasureId}</FormHelperText>
        </FormControl>

           <Autocomplete
                value={parent}
                sx={{ mb: '1.5rem' }}
                disabled={readOnly}
                options={ parents }
                getOptionLabel={ (option: any)  => option.nameRu }
                onChange={(e, newValue: { nameRu: string, nameKz: string, id: number | null } | null) => {
                    setParent(newValue)
                    formik.setFieldValue("parentId", newValue?.id, true)
                }}
                renderInput={(params: any) => (
                    <TextField
                        label={`${t("pages.admin.dictionary.selects.indicator")} *`}
                        name={"parentId"}
                        error={!!formik.touched.parentId && !!formik.errors.parentId}
                        helperText={formik.touched.parentId && formik.errors.parentId}
                        {...params}
                    />
                )}/>

        {readOnly ?
            <></>
            : <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <LoadingButton
                    loading={false}
                    type='submit'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                    }}>
                    <>{t("pages.admin.dictionary.buttons.save")}</>
                </LoadingButton>
            </Box>
        }
    </Box>
}


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
