import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, refreshToken } from "../../utils/token";

const protectedInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1/protected`,
    headers: { "Content-Type": "application/json" }
});

async function onRequestFulfilled(config: AxiosRequestConfig) {
    const accessToken = await getAccessToken();
    if (!config.headers || !accessToken) { return config}
    config.headers.Authorization = `Bearer ${accessToken}`
    return config;
}
function onRequestRejected(error: any) { return Promise.reject(error) }

async function onResponseFulfilled(response: AxiosResponse) {
    if(response.status !== 401) {
        return response;
    }

    try {
        await refreshToken();
        return protectedInstance.request(response.config)
    }
    catch (error) { return Promise.reject(error) }
}

async function onResponseRejected(error: any) {
    if(error.response.status !== 401) {
        return error;
    }
    try {
        await refreshToken();
        return protectedInstance.request(error.response.config)
    }
    catch (error) { return Promise.reject(error) }
}

protectedInstance.interceptors.request.use(onRequestFulfilled, onRequestRejected)
protectedInstance.interceptors.response.use(onResponseFulfilled, onResponseRejected)

export default protectedInstance;
