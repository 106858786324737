import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CircularProgress } from '@mui/material';
import { Provider as ReduxProvider } from "react-redux";
import { store } from './redux/store';
import "./i18n";

function Loading() {
    return (
        <div style={{
            display: "flex", justifyContent: "center", alignItems: "center",
            width: "100%", height: "100vh"
        }}>
            <CircularProgress color="primary" />
        </div>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <Suspense fallback={<Loading />}>
                <App />
            </Suspense>
        </ReduxProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
