import { AnyAction } from "redux"

export const SET_RATING_SELECT = "SET_RATING_SELECT";

const initialState = {
    ratingSelect: null,
}

export const ratingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_RATING_SELECT: return { ...state,
            ratingSelect: action.payload
        }

        default: return state
    }
}
