import {Box, Button, Menu, MenuItem} from "@mui/material";
import { useState } from "react";

import {useDispatch, useSelector} from "react-redux";
import { State } from "../../redux/store";
import { User } from "../../model/auth";
import { useTranslation } from "react-i18next";
import {i18translateType} from "../../i18n";
import {useNavigate} from "react-router-dom";
import { onLogout } from '../../utils/auth'
import {Authority} from "../../utils/common";
import {SET_USER} from "../../redux/reducers/auth";


export function ProfileMenu({ themeMode }: { themeMode: "light" | "dark" }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = !!anchorEl
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() { setAnchorEl(null) }

    const { t }: i18translateType = useTranslation();
    const state = useSelector((state: State) => state);
    const user: User | null = state.auth.user;
    const navigate = useNavigate()
    const dispatch = useDispatch();

    return <Box>
            <Button
                onClick={handleClick}
                color={themeMode === 'light' ? 'primary' : 'secondary'}>
                <span>{user?.lastName + ' ' + user?.firstName} </span>
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem component='a' onClick={() => {
                    handleClose();
                    navigate("/profile")
                }}>
                    {t("pages.account.user-reports")}
                </MenuItem>
                {
                    user?.authorities.includes(Authority.ADMIN) &&
                    <MenuItem component='a' onClick={() => {
                        handleClose();
                        navigate("/admin")
                    }}>
                        {t("pages.account.admin-page")}
                    </MenuItem>
                }
                <MenuItem component='a' onClick={() => {
                    handleClose();
                    onLogout();
                    dispatch({ type: SET_USER, payload: null });
                    navigate("/")
                }}>
                    {t("cabinet.logout")}
                </MenuItem>
            </Menu>
        </Box>
}
