export const pages = [
    {
        title: 'pages.admin.dictionaries',
        id: 'dictionary',
        pages: [
            {
                title: 'pages.admin.dictionary.period',
                href: '/dictionary/periods',
            },
            {
                title: 'pages.admin.dictionary.factor',
                href: '/dictionary/factors',
            },
            {
                title: 'pages.admin.dictionary.sub_factor',
                href: '/dictionary/sub-factors',
            },
            {
                title: 'pages.admin.dictionary.category',
                href: '/dictionary/categories',
            },
            {
                title: 'pages.admin.dictionary.sub_category',
                href: '/dictionary/sub-categories',
            },
            {
                title: 'pages.admin.dictionary.unit_measure',
                href: '/dictionary/unit-measures',
            },
            // {
            //     title: 'pages.admin.dictionary.kato',
            //     href: '/dictionary/kato-list',
            // },
            {
                title: 'pages.admin.dictionary.indicator',
                href: '/dictionary/indicators',
            },
            {
                title: 'pages.admin.dictionary.department',
                href: '/dictionary/departments',
            },
        ],
    },
    {
        title: 'pages.admin.reports',
        id: 'reports',
        pages: [
            {
                title: 'pages.reports',
                href: '/reports',
            }
        ],
    },
];
