import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {PeriodData} from "../../../../model/dictionary";
import {Box, TextField, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from '@emotion/styled';

export default function Form({onSubmit, initialValues, readOnly}: {onSubmit: (e: PeriodData) => void, initialValues: PeriodData,
    readOnly: boolean}) {
    const { t } = useTranslation();
    const validationSchema = yup.object({
        // id: yup
        //     .number(),
        code: yup
            .string()
            .trim()
            .required(t("validation.required")),
        nameKz: yup
            .string()
            .trim()
            .required(t("validation.required")),
        nameRu: yup
            .string()
            .trim()
            .required(t("validation.required")),
        dateBeg: yup
            .string()
            .trim()
            .required(t("validation.required")),
        dateEnd: yup
            .string()
            .trim()
            .required(t("validation.required")),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });
    return <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        sx={{ paddingRight: { sm: '3rem' } }}
        onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
        }}>

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.code")} *`}
            variant="outlined"
            name={'code'}
            fullWidth
            sx={{ mb: '1.5rem' }}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={!!formik.touched.code && !!formik.errors.code}
            helperText={formik.touched.code && formik.errors.code}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameKz")} *`}
            name={'nameKz'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameKz}
            onChange={formik.handleChange}
            error={!!formik.touched.nameKz && !!formik.errors.nameKz}
            helperText={formik.touched.nameKz && formik.errors.nameKz}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameRu")} *`}
            name={'nameRu'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameRu}
            onChange={formik.handleChange}
            error={!!formik.touched.nameRu && !!formik.errors.nameRu}
            helperText={formik.touched.nameRu && formik.errors.nameRu}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.dateBeg")} *`}
            name={'dateBeg'}
            placeholder="01.01"
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.dateBeg}
            onChange={formik.handleChange}
            error={!!formik.touched.dateBeg && !!formik.errors.dateBeg}
            helperText={formik.touched.dateBeg && formik.errors.dateBeg}
        />


        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.dateEnd")} *`}
            name={'dateEnd'}
            placeholder="31.12"
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.dateEnd}
            onChange={formik.handleChange}
            error={!!formik.touched.dateEnd && !!formik.errors.dateEnd}
            helperText={formik.touched.dateEnd && formik.errors.dateEnd}
        />
        {readOnly ?
            <></>
            : <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <LoadingButton
                    loading={false}
                    type='submit'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                    }}>
                    <>{t("pages.admin.dictionary.buttons.save")}</>
                </LoadingButton>
            </Box>
        }
    </Box>
}


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
