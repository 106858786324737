import React, {useState} from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import { Authority } from './utils/common';
import WithLayout from "./WithLayout";

import {
    Main as MainLayout,
    Cabinet as CabinetLayout,
    Public as PublicLayout
} from './layouts';

// Landing pages
import {
    Home as HomeView
} from './views/landing';

import {
    Login as LoginView
} from './views/auth';

import {
    NotFound as NotFoundView
} from './views/supporting';

import {
    AdminMain as AdminMainView,
    AdminUnitMeasures as  AdminUnitMeasuresView,
    AdminPeriods as AdminPeriodsView,
    AdminKatoList as AdminKatoListView,
    AdminDepartments as AdminDepartmentsView,
    AdminIndicators as AdminIndicatorsView,
    AdminCategories as AdminCategoriesView,
    AdminSubCategories as AdminSubCategoriesView,
    AdminFactors as AdminFactorsView,
    AdminSubFactors as AdminSubFactorsView,
} from './views/admin';

import {
    UserProfile as UserProfileView
} from './views/user';

import {
    ReportMain as ReportMainView,
    ReportRating as ReportRatingView,
    ReportView
} from './views/report';


import {
    Region as RegionView
} from './views/regions';

import { useAuth } from './utils/useAuth'

const Routes = () => {

    const { user } = useAuth();

    return (
        <Switch>
            {/* PUBLIC PAGES */}
            <Route
                path="/"
                element={
                    <WithLayout
                        component={HomeView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/almaty-region"
                element={
                    <WithLayout
                        component={RegionView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/almaty-region/:district"
                element={
                    <WithLayout
                        component={RegionView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/login"
                element={
                    <WithLayout
                        component={LoginView}
                        layout={PublicLayout}
                    />
                }
            />

            {/* ADMIN PAGES */}
            <Route
                path="/admin"
                element={
                    <WithLayout
                        component={AdminMainView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />

            <Route
                path="/dictionary/periods"
                element={
                    <WithLayout
                        component={AdminPeriodsView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/unit-measures"
                element={
                    <WithLayout
                        component={AdminUnitMeasuresView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/kato-list"
                element={
                    <WithLayout
                        component={AdminKatoListView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/indicators"
                element={
                    <WithLayout
                        component={AdminIndicatorsView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/categories"
                element={
                    <WithLayout
                        component={AdminCategoriesView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/sub-categories"
                element={
                    <WithLayout
                        component={AdminSubCategoriesView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/factors"
                element={
                    <WithLayout
                        component={AdminFactorsView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/sub-factors"
                element={
                    <WithLayout
                        component={AdminSubFactorsView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/dictionary/departments"
                element={
                    <WithLayout
                        component={AdminDepartmentsView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />

            {/* ACCOUNT PAGES */}
            <Route
                path="/profile"
                element={
                    <WithLayout
                        component={UserProfileView}
                        layout={CabinetLayout}
                    />
                }
            />

            <Route
                path="/reports"
                element={
                    <WithLayout
                        component={ReportMainView}
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/reports/:reportId"
                element={
                    <WithLayout
                        component={ReportRatingView}
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/view"
                element={
                    <WithLayout
                        component={ReportView}
                        layout={CabinetLayout}
                    />
                }
            />

            <Route
                path='*'
                element={
                    <WithLayout
                        component={NotFoundView}
                        layout={PublicLayout}
                    />
                }
            />
        </Switch>
    )
}

export default Routes
