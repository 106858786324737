import * as React from "react";

function SvgComponent(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 512 512" width={39.581} height={39.58} {...props}>
        <polygon fill="#5EBAE7" points="504.091,472.83 7.909,472.83 7.909,200.73 255.999,200.73 504.091,200.73 "/>
        <polygon fill="#36A9E1" points="255.999,40.672 506.024,200.73 255.999,376.794 10.65,202.268 "/>
        <g>
          <rect x="215.985" y="424.904" fill="#FFFFFF" width="16.006" height="15.818"/>
          <rect x="248" y="424.904" fill="#FFFFFF" width="16.005" height="15.818"/>
          <rect x="280.004" y="424.904" fill="#FFFFFF" width="16.006" height="15.818"/>
        </g>
        <g>
          <rect x="71.928" y="192.817" fill="#1D1D1B" width="32.012" height="15.818"/>
          <rect x="119.951" y="192.817" fill="#1D1D1B" width="32.012" height="15.818"/>
          <rect x="167.963" y="192.817" fill="#1D1D1B" width="32.012" height="15.818"/>
          <rect x="215.985" y="192.817" fill="#1D1D1B" width="32.012" height="15.818"/>
          <rect x="263.997" y="192.817" fill="#1D1D1B" width="32.012" height="15.818"/>
          <rect x="312.019" y="192.817" fill="#1D1D1B" width="32.011" height="15.818"/>
          <rect x="360.042" y="192.817" fill="#1D1D1B" width="32.011" height="15.818"/>
          <rect x="408.054" y="192.817" fill="#1D1D1B" width="32.012" height="15.818"/>
          <path fill="#1D1D1B" d="M255.999,31.26L0,196.421V480.74h512V196.421L255.999,31.26z M477.243,192.821h-21.17v15.818h23.209
		L255.999,367.097L32.718,208.639h23.208v-15.818h-21.17L255.999,50.083L477.243,192.821z M496.182,464.921H15.818V216.041
		l240.182,170.451l240.183-170.451L496.182,464.921L496.182,464.921z"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
      </svg>

  );
}

export default SvgComponent;
