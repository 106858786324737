import PropTypes from "prop-types";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import {DictionaryObject} from "../../../model/dictionary";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {dictionaryService} from "../../../api/services/dictionary";
import {reportService} from "../../../api/services/report";
import {CssTextField} from "../../../components/TextField/CssTextField";
import debounce from "lodash.debounce";

const ReportFilter = ({onSubmit}: {onSubmit: (e: any) => void;}) => {

    const { t } = useTranslation();
    const [year, setYear] = useState<number | null>(null)
    const [period, setPeriod] = useState<DictionaryObject | null>(null)
    const [report, setReport] = useState<DictionaryObject | null>(null)
    const [dictPeriods, setDictPeriods] = useState<DictionaryObject[]>([])
    const [dictReports, setDictReports] = useState<DictionaryObject[]>([])
    async function getDictPeriods() {
        try {
            const response = await dictionaryService.getDictPeriods()
            if(response.status === 'success' && response.data) {
                setDictPeriods(response.data)
            }
        } catch (ignored) {}
    }

    const constYearChangeHandler = useMemo(() => debounce(() => {
            if(!period) {
                //@ts-ignore
                getDictReports(year, period?.id)
            }
        }, 1000)
        , [year, period]);

    async function getDictReports(year: number, periodId: number) {
        try {
            const response = await reportService.getDictReport(year, periodId)
            if(response.status === 'success' && response.data) {
                setDictReports(response.data)
            }
        } catch (ignored) {}
    }

    useEffect(() => {
        getDictPeriods()
    }, [])
    return  <Grid item marginBottom={2} container spacing={2} alignItems={"flex-start"}>
                <Grid item xs={12} sm={1}>
                    <CssTextField
                        label={`${t("pages.admin.dictionary.inputs.year")} *`}
                        name={'year'}
                        type="number"
                        sx={{ mb: '1.5rem', mr: '1.5rem' }}
                        fullWidth color="primary" variant="outlined" size="small"
                        value={year}
                        onChange={(value) => {
                            setYear(Number(value.target.value))
                            if(period) {
                                constYearChangeHandler()
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>

                    <Autocomplete
                        getOptionLabel={ (option: any)  => option.nameRu }
                        size="small" sx={{ mb: .5 }}
                        options={dictPeriods}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={period}
                        onChange={(e, newValue: DictionaryObject | null) => {
                            setPeriod(newValue)
                            if(year) {
                                getDictReports(year!, newValue!.id)
                            }
                        }}
                        renderInput={params =>
                            <TextField {...params} fullWidth color="primary"
                                       name="period" label={`${t("pages.admin.dictionary.selects.period")} *`}/>
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={5}>

                    <Autocomplete
                        getOptionLabel={ (option: any)  => option.nameRu }
                        size="small" sx={{ mb: .5 }}
                        options={dictReports}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={report}
                        onChange={(e, newValue: DictionaryObject | null) => {
                            setReport(newValue)
                        }}
                        renderInput={params =>
                            <TextField {...params} fullWidth color="primary"
                                       name="period" label={`${t("pages.admin.dictionary.selects.report")} *`}/>
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={1}>
                    <Button fullWidth color="primary" variant="contained"
                            onClick={() => onSubmit({year: year, period: period, report: report})}>
                        <>{t("pages.admin.dictionary.buttons.search")}</>
                    </Button>
                </Grid>
    </Grid>


}

ReportFilter.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ReportFilter
