import {i18translateType} from "../../../i18n";

import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import LogoImg from "../../../assets/svg/Logo";
import {Link} from "react-scroll";
import styled from "styled-components";

const Footer = () => {
    const { t }: i18translateType = useTranslation();

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <Wrapper>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    align={'center'}
                    variant={'subtitle2'}
                    color="textSecondary"
                    style={{margin: 0}}
                    gutterBottom
                >

                        <div className="darkBg">
                            <div className="container">
                                <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
                                    <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
                                        <LogoImg />
                                        <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                                            Planner
                                        </h1>
                                    </Link>
                                    <StyleP className="whiteColor font13">
                                        © {getCurrentYear()} - <span className="purpleColor font13">Planner</span> Все права защищены
                                    </StyleP>

                                    <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
                                        На начало страницы
                                    </Link>
                                </InnerWrapper>
                            </div>
                        </div>
                </Typography>
            </Grid>
        </Grid>
        </Wrapper>
    )
};

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

export default Footer;
