import protectedInstance from "../instances/protected";
import {AxiosResponse} from "axios";
import {PaginationResponse, Response} from "../../model/api";
import {
    DistrictReport,
    IndicatorReport,
    RatingData,
    RatingTotalData,
    RegionReport,
    ReportData,
    ViewData
} from "../../model/report";
import publicInstance from "../instances/public";
import {DictionaryObject} from "../../model/dictionary";


async function getReports(page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .get(`/reports?${query}`)
        .then((res: AxiosResponse<PaginationResponse<ReportData[]>>) => res.data)
}

async function createReport(report: ReportData) {
    return protectedInstance
        .post(`/reports`, report)
        .then((res: AxiosResponse<Response<ReportData>>) => res.data)
}

async function updateReport(report: ReportData) {
    return protectedInstance
        .put(`/reports`, report)
        .then((res: AxiosResponse<Response<ReportData>>) => res.data)
}

async function removeReport(id: number) {
    return protectedInstance
        .delete(`/reports/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getReport(id: string | undefined) {
    return protectedInstance
        .get(`/reports/${id || '0'}`)
        .then((res: AxiosResponse<Response<ReportData>>) => res.data)
}

async function getRatings(page: number, pageSize: number, reportId: string, q?: string | undefined) {
    const query = `page=${page}&page_size=${pageSize}${!!q ? `&${q}`: ''}`
    return protectedInstance
        .get(`/reports/${reportId}/ratings?${query}`)
        .then((res: AxiosResponse<PaginationResponse<RatingData[]>>) => res.data)
}

async function createRating(reportId: string | undefined, rating: RatingData) {
    return protectedInstance
        .post(`/reports/${reportId || '0'}/ratings`, rating)
        .then((res: AxiosResponse<Response<RatingData>>) => res.data)
}

async function updateRating(reportId: string | undefined, rating: RatingData) {
    return protectedInstance
        .put(`/reports/${reportId || '0'}/ratings`, rating)
        .then((res: AxiosResponse<Response<RatingData>>) => res.data)
}

async function removeRating(id: number) {
    return protectedInstance
        .delete(`/ratings/${id}`)
        .then((res: AxiosResponse<Response<void>>) => res.data)
}

async function getViews(reportId: number) {
    return protectedInstance
        .get(`/view?reportId=${reportId}`)
        .then((res: AxiosResponse<Response<ViewData>>) => res.data)
}

async function downloadReport(year: number, periodName: string, content: string, endpoint: string) {
    let bufferObject = new Blob([`<!DOCTYPE html><html lang="en"><head><style>.keep-together{page-break-inside: avoid;}.break-before{page-break-before: always;}.break-after {page-break-after: always;}.fulljustify{text-align: justify;}
.fulljustify:after{content: "";display: inline-block;width: 100%;}.circle{border-radius: 50%;width: 8px;height: 8px;padding: 0;margin: 0;align-self: center;}
.red{background-color: red}.orange{background-color: orange}.green{background-color: green}div.first.column>* {padding: 0 20px;}div.last.column>*{padding:0 20px;}</style><meta charset="utf-8"><title></title></head><body>${content}</body></html>`], {
        type: 'text/html'
    });

    const file = new FormData();
    file.append('file', bufferObject, "report.html");

    return protectedInstance.post(
        `/view/${endpoint}?year=${year}&periodName=${periodName}`, //your url
        file, { responseType: 'blob', headers: { 'Content-Type': 'multipart/form-data'}})
        .then((response) => {
            downloadFromResponse(response)
        });
}

async function downloadMethod() {

    return protectedInstance.get(`/reports/file-method`, { responseType: 'blob'})
        .then((response) => {
            downloadFromResponse(response)
        });
}

async function downloadRatingPlace(reportId: number) {
    return publicInstance.get(`/reports/rating-place?reportId=${reportId}`, { responseType: 'blob'})
        .then((response) => {
            downloadFromResponse(response)
        });
}

async function getRecalc(id: number, page: number, pageSize: number, q?: string) {
    const query = `query=${q || ''}&page=${page}&page_size=${pageSize}`
    return protectedInstance
        .put(`/reports/${id}/calc?${query}`)
        .then((res: AxiosResponse<PaginationResponse<ReportData[]>>) => res.data)
}

async function getRegionReport(reportId: number, indicatorIds: string) {
    return publicInstance
        .get(`/reports/almaty-region?reportId=${reportId}&indicatorIds=${indicatorIds}`)
        .then((res: AxiosResponse<Response<IndicatorReport[]>>) => res.data)
}

async function getDistrictReport(district: string, year: number, periodId: number) {
    return publicInstance
        .get(`/reports/almaty-region/${district}?year=${year}&periodId=${periodId}`)
        .then((res: AxiosResponse<Response<DistrictReport>>) => res.data)
}

async function getDictPeriods() {
    return publicInstance
        .get(`/nsi/periods/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictIndicators() {
    return publicInstance
        .get(`/nsi/indicators/dict`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}

async function getDictReport(year: number, periodId: number) {
    return publicInstance
        .get(`/reports/dict?year=${year}&periodId=${periodId}`)
        .then((res: AxiosResponse<Response<DictionaryObject[]>>) => res.data)
}


async function getTotals(reportId: number) {
    return publicInstance
        .get(`/reports/totals?reportId=${reportId}`)
        .then((res: AxiosResponse<Response<RatingTotalData[]>>) => res.data)
}

function downloadFromResponse(response: AxiosResponse) {
    const contentDisposition = response.headers['content-disposition'];
    if(contentDisposition) {
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', decodeURI(contentDisposition).replace('attachment; filename*=UTF-8\'\'', '')); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
}


export const reportService = {
    getReports,
    createReport,
    updateReport,
    removeReport,
    getReport,
    getRatings,
    createRating,
    updateRating,
    removeRating,
    getViews,
    downloadReport,
    getRecalc,
    downloadMethod,
    getRegionReport,
    getDistrictReport,
    getDictPeriods,
    getDictIndicators,
    getDictReport,
    getTotals,
    downloadRatingPlace
}
