import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';
import { Pages } from '../../../../model/pages';

const Sidebar = ({
                     pages, open, variant, onClose, themeMode, ...rest
                 }: {
    pages: Pages;
    open: boolean;
    variant?: 'permanent' | 'persistent' | 'temporary';
    onClose: () => void;
    themeMode: "light" | "dark"
}) => {
    return (
        <Drawer
            anchor="left"
            onClose={() => onClose()}
            open={open}
            variant={variant}
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: 256,
                    top: { xs: 0, md: 90 },
                    height: { xs: '100%', md: 'calc(100% - 90px - 100px)' },
                },
            }}>
            <Box padding={2}>
                <SidebarNav pages={pages} onClose={onClose} themeMode={themeMode} />
            </Box>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
    pages: PropTypes.array.isRequired,
};

export default Sidebar;
