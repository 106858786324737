import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {i18translateType} from "../i18n";

export default function NoRowsOverlay() {
    const { t }: i18translateType = useTranslation();

    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            {t("table.noRows")}
        </Stack>
    )
}
