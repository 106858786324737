import React, {useState, ReactElement} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Topbar, Sidebar, Footer } from '../components';
import { pages } from '../navigation';
import {AppBar} from "@mui/material";

const Main = ({
                  children,
                  themeToggle,
                  themeMode,
                  setThemePalette,
                  paletteType,
              }: {
    children: ReactElement;
    themeToggle: () => void;
    themeMode: "light" | "dark";
    setThemePalette: (type?: string) => void;
    paletteType: string;
}) => {
    const theme = useTheme();
    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    return <>
                <Box height="100%" width="100%" style={{minHeight: 'calc(100vh - 100px)'}}>
                        <AppBar
                            position={'sticky'}
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                            }}
                            elevation={1}
                        >
                        <Topbar
                            onSidebarOpen={handleSidebarOpen}
                            themeMode={themeMode}
                            themeToggle={themeToggle}
                            setThemePalette={setThemePalette}
                            paletteType={paletteType}
                            removeLandingLinks={false}
                        />
                    </AppBar>
                    <Sidebar
                        onClose={handleSidebarClose}
                        open={openSidebar}
                        variant="temporary"
                        pages={pages}
                    />
                    <main>
                        <Box  display="flex"
                              flex="1 1 auto"/>
                        <Box display="flex" flex="1 1 auto">
                            <Box flex="1 1 auto" display="flex" flexDirection="column" justifyContent="space-between">
                                {children}
                            </Box>
                        </Box>
                    </main>
                </Box>
                <Footer />
        </>
};

Main.propTypes = {
    children: PropTypes.node,
    themeToggle: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
    setThemePalette: PropTypes.func.isRequired,
    paletteType: PropTypes.string.isRequired,
};

export default Main;
