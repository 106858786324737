import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {DictionaryObject} from "../../../../model/dictionary";
import {
    Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from '@emotion/styled';
import {useEffect, useState} from "react";
import {dictionaryService} from "../../../../api/services/dictionary";
import {RatingCommand, RatingData, RatingSelect} from "../../../../model/report";
import {reportService} from "../../../../api/services/report";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../redux/store";
import {SET_RATING_SELECT} from "../../../../redux/reducers/rating";
import {getRatingSelect, setRatingSelect} from "../../../../utils/rating";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

export default function Form({initialValues, readOnly, snack, reportId, command}: {initialValues: RatingData,
    readOnly: boolean, snack: (message: string, variant: "success" | "error" | "info" | "warning") => string | number, reportId: string, command: RatingCommand}) {
    const { t } = useTranslation();

    const [inValue, setInValue] = useState(initialValues)
    const [saved, setSaved] = useState(false)
    const validationSchema = yup.object({
        categoryId: yup
            .number()
            .nullable()
            .required(t("validation.required"))
            .transform((_, val) => val === Number(val) ? val : null),
        subCategoryId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null),
        factorId: yup
            .number()
            .nullable()
            .required(t("validation.required"))
            .transform((_, val) => val === Number(val) ? val : null),
        subFactorId: yup
            .number()
            .nullable(true)
            .transform((_, val) => val === Number(val) ? val : null),
        indicatorId: yup
            .number()
            .nullable()
            .required(t("validation.required")),
        regionValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        balhashValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        balhashScore: yup
            .number()
            .nullable(true),
        balhashPlace: yup
            .number()
            .nullable(true),
        enbekValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        enbekScore: yup
            .number()
            .nullable(true),
        enbekPlace: yup
            .number()
            .nullable(true),
        zhanbylValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        zhanbylScore: yup
            .number()
            .nullable(true),
        zhanbylPlace: yup
            .number()
            .nullable(true),
        iliValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        iliScore: yup
            .number()
            .nullable(true),
        iliPlace: yup
            .number()
            .nullable(true),
        karasayValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        karasayScore: yup
            .number()
            .nullable(true),
        karasayPlace: yup
            .number()
            .nullable(true),
        kegenValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        kegenScore: yup
            .number()
            .nullable(true),
        kegenPlace: yup
            .number()
            .nullable(true),
        raymbekValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        raymbekScore: yup
            .number()
            .nullable(true),
        raymbekPlace: yup
            .number()
            .nullable(true),
        talgarValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        talgarScore: yup
            .number()
            .nullable(true),
        talgarPlace: yup
            .number()
            .nullable(true),
        uygurValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        uygurScore: yup
            .number()
            .nullable(true),
        uygurPlace: yup
            .number()
            .nullable(true),
        konaevValue: yup
            .number()
            .typeError(t("validation.incorrect"))
            .min(0, t("validation.min") + 0)
            .required(t("validation.required")),
        konaevScore: yup
            .number()
            .nullable(true),
        konaevPlace: yup
            .number()
            .nullable(true)

    });
    const [dictCategories, setDictCategories] = useState<DictionaryObject[]>([])
    const [dictSubCategories, setDictSubCategories] = useState<DictionaryObject[]>([])
    const [dictFactors, setDictFactors] = useState<DictionaryObject[]>([])
    const [dictSubFactors, setDictSubFactors] = useState<DictionaryObject[]>([])
    const [dictIndicators, setDictIndicators] = useState<DictionaryObject[]>([])
    const [indicator, setIndicator] = useState<{nameRu: string, nameKz: string, id: number | null} | null>(
        {nameRu: initialValues.indicatorNameRu, nameKz: initialValues.indicatorNameKz, id: initialValues.indicatorId})

    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    const ratingSelect: RatingSelect | null = state.rating.ratingSelect;

    async function getDictCategories() {
        try {
            const response = await dictionaryService.getDictCategories()
            if(response.status === 'success' && response.data) {
                setDictCategories(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictFactors() {
        try {
            const response = await dictionaryService.getDictFactors()
            if(response.status === 'success' && response.data) {
                setDictFactors(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictIndicators() {
        try {
            const response = await dictionaryService.getDictIndicators()
            if(response.status === 'success' && response.data) {
                setDictIndicators(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictSubCategories(categoryId: number) {
        if(!categoryId) {
            setDictSubCategories([])
            return
        }
        try {
            const response = await dictionaryService.getDictSubCategoriesByCategoryId(categoryId)
            if(response.status === 'success' && response.data) {
                setDictSubCategories(response.data)
            }
        } catch (ignored) {}
    }

    async function getDictSubFactors(factorId: number) {
        if(!factorId) {
            setDictSubFactors([])
            return
        }
        try {
            const response = await dictionaryService.getDictSubFactorsByFactorId(factorId)
            if(response.status === 'success' && response.data) {
                setDictSubFactors(response.data)
            }
        } catch (ignored) {}
    }

    useEffect(() => {
        getDictCategories()
        getDictFactors()
        getDictIndicators()

        if(command === RatingCommand.ADD) {
            let ratingSelectValue
            if(!ratingSelect) {
                ratingSelectValue = getRatingSelect();
                dispatch({type: SET_RATING_SELECT, payload: ratingSelectValue});
            } else {
                ratingSelectValue  = ratingSelect
            }
            formik.setFieldValue("factorId", ratingSelectValue?.factorId, true)
            if(ratingSelectValue?.factorId) {
                getDictSubFactors(ratingSelectValue?.factorId)
            }
            formik.setFieldValue("subFactorId", ratingSelectValue?.subFactorId, false)
            formik.setFieldValue("categoryId", ratingSelectValue?.categoryId, true)
            if(ratingSelectValue?.categoryId) {
                getDictSubCategories(ratingSelectValue?.categoryId)
            }
            formik.setFieldValue("subCategoryId", ratingSelectValue?.subCategoryId, false)
        }

        else if([RatingCommand.VIEW, RatingCommand.UPDATE].includes(command)) {
            if(initialValues?.factorId) {
                getDictSubFactors(initialValues?.factorId)
            }

            if(initialValues?.categoryId) {
                getDictSubCategories(initialValues?.categoryId)
            }
        }
    }, [])




    function saveRatingSelect(ratingSelect: RatingSelect) {
        dispatch({type: SET_RATING_SELECT, payload: ratingSelect});
        setRatingSelect(ratingSelect)
    }


    function convert(value: number | null): string {
        if(!value) {
            return ''
        }
        return `${value}`
    }

     async function onSave(e: RatingData): Promise<void> {
        try {
            const response = !initialValues.id ? await reportService.createRating(reportId, e) :
                await reportService.updateRating(reportId, e)

            if (response.status === "success" && response.data) {
                setInValue(response.data)
                saveRatingSelect({
                    factorId: response.data.factorId,
                    subFactorId: response.data.subFactorId,
                    categoryId: response.data.categoryId,
                    subCategoryId: response.data.subCategoryId,
                })
                setSaved(true)
                snack(t("snack.success.action"), "success");
            }
        } catch (error) {
            snack(t("snack.error.announcements_registry"), "error");
        } finally {}
    }

    function nextIndicator() {
        setSaved(false)
        setIndicator(null)
        setInValue(
            { ...initialValues,
                factorId: ratingSelect?.factorId || null,
                subFactorId: ratingSelect?.subFactorId || null,
                categoryId: ratingSelect?.categoryId || null,
                subCategoryId: ratingSelect?.subCategoryId || null
            }
        )
    }

    const formik = useFormik({
        initialValues: inValue,
        validationSchema: validationSchema,
        onSubmit: onSave,
        enableReinitialize: true
    });

    return <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        sx={{ paddingRight: { sm: '3rem' } }}
        onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
        }}>

        <Grid container spacing={2} sx={{mb: '1em'}}>
            <Grid item xs={6}>
            <FormControl fullWidth>
                <InputLabel id="select-label-factor">{`${t("pages.admin.dictionary.selects.factor")} *`}</InputLabel>
                <Select
                    labelId="select-label-factor"
                    id="select-factor"
                    name={"factorId"}
                    label={`${t("pages.admin.dictionary.selects.factor")} *`}
                    disabled={readOnly}
                    size={"small"}
                    value={convert(formik.values.factorId) || ''}
                    error={!!formik.touched.factorId && !!formik.errors.factorId}
                    onChange={(value) => {
                        formik.setFieldValue("factorId", Number(value.target.value), true)
                        formik.setFieldValue("subFactorId", null, true)
                        getDictSubFactors(Number(value.target.value))
                    }}>
                    {
                        dictFactors.map((value, index) => (
                            <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText error={!!formik.touched.factorId && !!formik.errors.factorId}>{(formik.errors.factorId && formik.touched.factorId) && formik.errors.factorId}</FormHelperText>
            </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="select-label-subFactor">{`${t("pages.admin.dictionary.selects.subFactor")}`}</InputLabel>
                    <Select
                        labelId="select-label-subFactor"
                        id="select-subFactor"
                        name={"subFactorId"}
                        label={`${t("pages.admin.dictionary.selects.subFactor")}`}
                        size={"small"}
                        disabled={readOnly}
                        displayEmpty
                        sx={{"& .MuiSelect-iconOutlined": {display: formik.values.subFactorId? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                        endAdornment={<IconButton sx={{visibility: formik.values.subFactorId? "visible": "hidden"}} onClick={(e) =>{
                            e.preventDefault()
                            formik.setFieldValue("subFactorId", null, true)
                        }}><ClearIcon/></IconButton>}
                        value={convert(formik.values.subFactorId) || ''}
                        error={!!formik.touched.categoryId && !!formik.errors.subFactorId}
                        onChange={(value) => formik.setFieldValue("subFactorId", Number(value.target.value), true)}>
                        {
                            dictSubFactors.map((value, index) => (
                                <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                            ))
                        }
                    </Select>
                    <FormHelperText error={!!formik.touched.subFactorId && !!formik.errors.subFactorId}>{(formik.errors.subFactorId && formik.touched.subFactorId) && formik.errors.subFactorId}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="select-label-category">{`${t("pages.admin.dictionary.selects.category")} *`}</InputLabel>
                    <Select
                        labelId="select-label-category"
                        id="select-category"
                        name={"categoryId"}
                        size={"small"}
                        label={`${t("pages.admin.dictionary.selects.category")} *`}
                        disabled={readOnly}
                        value={convert(formik.values.categoryId) || ''}
                        error={!!formik.touched.categoryId && !!formik.errors.categoryId}
                        onChange={(value) => {
                            formik.setFieldValue("categoryId", Number(value.target.value), true)
                            formik.setFieldValue("subCategoryId", null, true)
                            getDictSubCategories(Number(value.target.value))
                        }}>
                        {
                            dictCategories.map((value, index) => (
                                <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                            ))
                        }
                    </Select>
                    <FormHelperText error={!!formik.touched.categoryId && !!formik.errors.categoryId}>{(formik.errors.categoryId && formik.touched.categoryId) && formik.errors.categoryId}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl fullWidth>
                <InputLabel id="select-label-subCategory">{`${t("pages.admin.dictionary.selects.subCategory")}`}</InputLabel>
                <Select
                    labelId="select-label-subCategory"
                    id="select-subCategory"
                    name={"subCategoryId"}
                    label={`${t("pages.admin.dictionary.selects.subCategory")}`}
                    size={"small"}
                    displayEmpty
                    disabled={readOnly}
                    value={convert(formik.values.subCategoryId) || ''}
                    sx={{"& .MuiSelect-iconOutlined": {display: formik.values.subCategoryId? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                    endAdornment={<IconButton sx={{visibility: formik.values.subCategoryId? "visible": "hidden"}} onClick={(e) =>{
                        e.preventDefault()
                        formik.setFieldValue("subCategoryId", null, true)
                    }}><ClearIcon/></IconButton>}
                    error={!!formik.touched.categoryId && !!formik.errors.subCategoryId}
                    onChange={(value) => formik.setFieldValue("subCategoryId", Number(value.target.value), true)}>
                    {
                        dictSubCategories.map((value, index) => (
                            <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText error={!!formik.touched.subCategoryId && !!formik.errors.subCategoryId}>{(formik.errors.subCategoryId && formik.touched.subCategoryId) && formik.errors.subCategoryId}</FormHelperText>
            </FormControl>
            </Grid>
            <Grid item xs={12}>

            <Autocomplete
                value={indicator}
                size={"small"}
                disabled={readOnly}
                options={ dictIndicators }
                getOptionLabel={ (option: any)  => option.nameRu }
                onChange={(e, newValue: { nameRu: string, nameKz: string, id: number | null } | null) => {
                    setIndicator(newValue)
                    formik.setFieldValue("indicatorId", newValue?.id, true)
                }}
                renderInput={(params: any) => (
                    <TextField
                        label={`${t("pages.admin.dictionary.selects.indicator")} *`}
                        name={"indicatorId"}
                        error={!!formik.touched.indicatorId && !!formik.errors.indicatorId}
                        helperText={formik.touched.indicatorId && formik.errors.indicatorId}
                        {...params}
                    />
                )}/>
            </Grid>
            <Grid item xs={3}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    label={`${t("pages.admin.dictionary.inputs.regionValue")} *`}
                    name={'regionValue'}
                    fullWidth
                    variant='outlined'
                    size={"small"}
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.regionValue || ''}
                    onChange={formik.handleChange}
                    error={!!formik.touched.regionValue && !!formik.errors.regionValue}
                    helperText={formik.touched.regionValue && formik.errors.regionValue}
                />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    label={`${t("pages.admin.dictionary.inputs.balhashValue")} *`}
                    name={'balhashValue'}
                    size={"small"}
                    variant='outlined'
                    fullWidth
                    value={formik.values.balhashValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.balhashValue && !!formik.errors.balhashValue}
                    helperText={formik.touched.balhashValue && formik.errors.balhashValue}
                />
            </Grid>
            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.balhashScore")} *`}
                    name={'balhashScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.balhashScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.balhashScore && !!formik.errors.balhashScore}
                    helperText={formik.touched.balhashScore && formik.errors.balhashScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.balhashPlace")} *`}
                    name={'balhashPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.balhashPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.balhashPlace && !!formik.errors.balhashPlace}
                    helperText={formik.touched.balhashPlace && formik.errors.balhashPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.enbekValue")} *`}
                    name={'enbekValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.enbekValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.enbekValue && !!formik.errors.enbekValue}
                    helperText={formik.touched.enbekValue && formik.errors.enbekValue}
                />
            </Grid>
            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.enbekScore")} *`}
                    name={'enbekScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.enbekScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.enbekScore && !!formik.errors.enbekScore}
                    helperText={formik.touched.enbekScore && formik.errors.enbekScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.enbekPlace")} *`}
                    name={'enbekPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.enbekPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.enbekScore && !!formik.errors.enbekPlace}
                    helperText={formik.touched.enbekScore && formik.errors.enbekPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.zhanbylValue")} *`}
                    name={'zhanbylValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.zhanbylValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.zhanbylValue && !!formik.errors.zhanbylValue}
                    helperText={formik.touched.zhanbylValue && formik.errors.zhanbylValue}
                />
            </Grid>
            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.zhanbylScore")} *`}
                    name={'zhanbylScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.zhanbylScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.zhanbylScore && !!formik.errors.zhanbylScore}
                    helperText={formik.touched.zhanbylScore && formik.errors.zhanbylScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.zhanbylPlace")} *`}
                    name={'zhanbylPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.zhanbylPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.zhanbylPlace && !!formik.errors.zhanbylPlace}
                    helperText={formik.touched.zhanbylPlace && formik.errors.zhanbylPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.iliValue")} *`}
                    name={'iliValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.iliValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.iliValue && !!formik.errors.iliValue}
                    helperText={formik.touched.iliValue && formik.errors.iliValue}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.iliScore")} *`}
                    name={'iliScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.iliScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.iliScore && !!formik.errors.iliScore}
                    helperText={formik.touched.iliScore && formik.errors.iliScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.iliPlace")} *`}
                    name={'iliPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.iliPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.iliPlace && !!formik.errors.iliPlace}
                    helperText={formik.touched.iliPlace && formik.errors.iliPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.karasayValue")} *`}
                    name={'karasayValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.karasayValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.karasayValue && !!formik.errors.karasayValue}
                    helperText={formik.touched.karasayValue && formik.errors.karasayValue}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.karasayScore")} *`}
                    name={'karasayScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.karasayScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.karasayScore && !!formik.errors.karasayScore}
                    helperText={formik.touched.karasayScore && formik.errors.karasayScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.karasayPlace")} *`}
                    name={'karasayPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.karasayPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.karasayScore && !!formik.errors.karasayPlace}
                    helperText={formik.touched.karasayScore && formik.errors.karasayScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.kegenValue")} *`}
                    name={'kegenValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.kegenValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.kegenValue && !!formik.errors.kegenValue}
                    helperText={formik.touched.kegenValue && formik.errors.kegenValue}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.kegenScore")} *`}
                    name={'kegenScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.kegenScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.kegenScore && !!formik.errors.kegenScore}
                    helperText={formik.touched.kegenScore && formik.errors.kegenScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.kegenPlace")} *`}
                    name={'kegenPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.kegenPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.kegenPlace && !!formik.errors.kegenPlace}
                    helperText={formik.touched.kegenPlace && formik.errors.kegenPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.raymbekValue")} *`}
                    name={'raymbekValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.raymbekValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.kegenValue && !!formik.errors.raymbekValue}
                    helperText={formik.touched.kegenValue && formik.errors.raymbekValue}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.raymbekScore")} *`}
                    name={'raymbekScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.raymbekScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.raymbekScore && !!formik.errors.raymbekScore}
                    helperText={formik.touched.raymbekScore && formik.errors.raymbekScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.raymbekPlace")} *`}
                    name={'raymbekPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.raymbekPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.raymbekPlace && !!formik.errors.raymbekPlace}
                    helperText={formik.touched.raymbekPlace && formik.errors.raymbekPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.talgarValue")} *`}
                    name={'talgarValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.talgarValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.talgarValue && !!formik.errors.talgarValue}
                    helperText={formik.touched.talgarValue && formik.errors.talgarValue}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.talgarScore")} *`}
                    name={'talgarScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.talgarScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.talgarScore && !!formik.errors.talgarScore}
                    helperText={formik.touched.talgarScore && formik.errors.talgarScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.talgarPlace")} *`}
                    name={'talgarPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.talgarPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.talgarPlace && !!formik.errors.talgarPlace}
                    helperText={formik.touched.talgarPlace && formik.errors.talgarPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.uygurValue")} *`}
                    name={'uygurValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.uygurValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.uygurValue && !!formik.errors.uygurValue}
                    helperText={formik.touched.uygurValue && formik.errors.uygurValue}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.uygurScore")} *`}
                    name={'uygurScore'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.uygurScore || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.uygurScore && !!formik.errors.uygurScore}
                    helperText={formik.touched.uygurScore && formik.errors.uygurScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.uygurPlace")} *`}
                    name={'uygurPlace'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.uygurPlace || ''}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    error={!!formik.touched.uygurPlace && !!formik.errors.uygurPlace}
                    helperText={formik.touched.uygurPlace && formik.errors.uygurPlace}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={readOnly}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.konaevValue")} *`}
                    name={'konaevValue'}
                    variant='outlined'
                    fullWidth
                    value={formik.values.konaevValue}
                    onChange={formik.handleChange}
                    error={!!formik.touched.konaevValue && !!formik.errors.konaevValue}
                    helperText={formik.touched.konaevValue && formik.errors.konaevValue}
                />
            </Grid>
            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.konaevScore")} *`}
                    name={'konaevScore'}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.konaevScore || ''}
                    onChange={formik.handleChange}
                    error={!!formik.touched.konaevScore && !!formik.errors.konaevScore}
                    helperText={formik.touched.konaevScore && formik.errors.konaevScore}
                />
            </Grid>

            <Grid item xs={2}>
                <CssTextField
                    type="number"
                    disabled={true}
                    size={"small"}
                    label={`${t("pages.admin.dictionary.inputs.konaevPlace")} *`}
                    name={'konaevPlace'}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.konaevPlace || ''}
                    onChange={formik.handleChange}
                    error={!!formik.touched.konaevPlace && !!formik.errors.konaevPlace}
                    helperText={formik.touched.konaevPlace && formik.errors.konaevPlace}
                />
            </Grid>
        </Grid>

        {readOnly ?
            <></>
            : <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                { command !== RatingCommand.ADD ? <></> :
                    <LoadingButton
                        loading={false}
                        variant='contained'
                        disabled={!saved}
                        sx={{
                            py: '0.8rem',
                            mt: 2,
                            mr: '1.5rem'
                        }} onClick={(e) => {
                            e.preventDefault()
                            nextIndicator()
                        }
                    }>
                        <>{t("pages.admin.dictionary.buttons.next")}</>
                    </LoadingButton>
                }

                    <LoadingButton
                        loading={false}
                        type='submit'
                        variant='contained'
                        sx={{
                            py: '0.8rem',
                            mt: 2,
                            mr: '1.5rem'
                        }}>
                        <>{t("pages.admin.dictionary.buttons.save")}</>
                    </LoadingButton>

            </Box>
        }
    </Box>
}


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
