import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {DictionaryData, DictionaryObject, SubCategoryData, SubFactorData} from "../../../../model/dictionary";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from '@emotion/styled';
import {useEffect, useState} from "react";
import {dictionaryService} from "../../../../api/services/dictionary";

export default function Form({onSubmit, initialValues, readOnly}: {onSubmit: (e: SubFactorData) => void, initialValues: SubFactorData,
    readOnly: boolean}) {
    const { t } = useTranslation();
    const validationSchema = yup.object({
        // id: yup
        //     .number(),
        code: yup
            .string()
            .trim()
            .required(t("validation.required"))
            .max(2, t("validation.max_length") + ' 2'),
        nameKz: yup
            .string()
            .trim()
            .required(t("validation.required")),
        nameRu: yup
            .string()
            .trim()
            .required(t("validation.required")),
        factorId: yup
            .number()
            .nullable()
            .required(t("validation.required"))
            .transform((_, val) => val === Number(val) ? val : null)

    });
    const [dictFactors, setDictFactors] = useState<DictionaryObject[]>([])

    async function getDictFactors() {
        try {
            const response = await dictionaryService.getDictFactors()
            if(response.status === 'success' && response.data) {
                setDictFactors(response.data)
            }
        } catch (ignored) {}
    }
    useEffect(() => {
        getDictFactors()
    }, [])

    function convert(value: number | null): string {
        if(!value) {
            return ''
        }
        return `${value}`
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });
    return <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        sx={{ paddingRight: { sm: '3rem' } }}
        onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
        }}>

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.code")} *`}
            variant="outlined"
            name={'code'}
            fullWidth
            sx={{ mb: '1.5rem' }}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={!!formik.touched.code && !!formik.errors.code}
            helperText={formik.touched.code && formik.errors.code}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameKz")} *`}
            name={'nameKz'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameKz}
            onChange={formik.handleChange}
            error={!!formik.touched.nameKz && !!formik.errors.nameKz}
            helperText={formik.touched.nameKz && formik.errors.nameKz}
        />

        <CssTextField
            disabled={readOnly}
            label={`${t("pages.admin.dictionary.inputs.nameRu")} *`}
            name={'nameRu'}
            fullWidth
            variant='outlined'
            sx={{ mb: '1.5rem' }}
            value={formik.values.nameRu}
            onChange={formik.handleChange}
            error={!!formik.touched.nameRu && !!formik.errors.nameRu}
            helperText={formik.touched.nameRu && formik.errors.nameRu}
        />
        <FormControl>
            <InputLabel id="select-label-factor">{`${t("pages.admin.dictionary.selects.factor")} *`}</InputLabel>
            <Select
                labelId="select-label-factor"
                id="select-factor"
                name={"factorId"}
                label={`${t("pages.admin.dictionary.selects.factor")} *`}
                disabled={readOnly}
                value={convert(formik.values.factorId)}
                error={!!formik.touched.factorId && !!formik.errors.factorId}
                onChange={(value) => formik.setFieldValue("factorId", Number(value.target.value), true)}>
                <MenuItem key={''} value={''}>{' '}</MenuItem>
                {
                    dictFactors.map((value, index) => (
                        <MenuItem key={index} value={value.id}>{value.nameRu}</MenuItem>
                    ))
                }
                </Select>
            <FormHelperText error={!!formik.touched.factorId && !!formik.errors.factorId}>{(formik.errors.factorId && formik.touched.factorId) && formik.errors.factorId}</FormHelperText>
        </FormControl>

        {readOnly ?
            <></>
            : <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <LoadingButton
                    loading={false}
                    type='submit'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                    }}>
                    <>{t("pages.admin.dictionary.buttons.save")}</>
                </LoadingButton>
            </Box>
        }
    </Box>
}


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5e5b5d',
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderColor: '#c8d0d4',
    },
    '& .MuiInput-underline:after': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d32f2f',
            },
        },
        '& fieldset': {
            borderColor: '#c8d0d4',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            border: '1px solid #c8d0d4',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #c8d0d4',
        },
    },
});
