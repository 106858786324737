import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../../../components/Elements/ProjectBox";
import FullButton from "../../../components/Buttons/FullButton";
// Assets
import Almaty from "../../../assets/img/regions/almaty.jpg";
import Zhambyl from "../../../assets/img/regions/zhambyl.png";
import Ile from "../../../assets/img/regions/ile.png";
import Balhash from "../../../assets/img/regions/balhash.png";
import Enbekshi from "../../../assets/img/regions/enbekshi.png";
import Karasay from "../../../assets/img/regions/karasay.png";
import Kegen from "../../../assets/img/regions/kegen.png";
import Raymbek from "../../../assets/img/regions/raymbek.png";
import Talgar from "../../../assets/img/regions/talgar.png";
import Konayev from "../../../assets/img/regions/konayev.png";
import Uygur from "../../../assets/img/regions/uygur.png";
import {useNavigate} from "react-router-dom";
export default function Projects() {

    const navigate = useNavigate();

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Информация по районам</h1>
            {/*<p className="font13">*/}
            {/*  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
            {/*  <br />*/}
            {/*  labore et dolore magna aliquyam erat, sed diam voluptua.*/}
            {/*</p>*/}
          </HeaderInfo>
          <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                    img={Almaty}
                    show={false}
                    title={"Алматинская область"}
                    territory={"3,6 тыс.кв.км"}
                    population={"218 513 человек"}
                    county={"10"}
                    settlements={"48 (47 СНП и 1 город)"}
                    text={"Специализация района – аграрно-промышленной  направленности."}
                    action={() => navigate("/almaty-region")}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={Zhambyl}
                  title={"Жамбылский район"}
                  territory={"19,3 тыс.кв.км."}
                  population={"175 136 человек на 1 июля 2022 года"}
                  county={"24"}
                  settlements={"61"}
                  text={"Специализация района – аграрно-промышленной направленности."}
                  action={() => navigate("/almaty-region/zhambyl")}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={Ile}
                  title={"Илийский район"}
                  territory={"7,7 тыс.кв.км."}
                  population={"234 368 человек на 1 июля 2022 года"}
                  county={"10"}
                  settlements={"30"}
                  text={"Специализация района – промышленно-аграрный район."}
                  action={() => navigate("/almaty-region/ile")}
                />
              </div>
            </div>
            <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Balhash}
                title={"Балхашский район"}
                territory={"37,4 тыс.кв.км."}
                population={"29 517 человек на 1 июля 2022 года "}
                county={"15"}
                settlements={"28"}
                text={"Специализация района – аграрный район, а также специализируется на переработке сельскохозяйственной продукции."}
                action={() => navigate("/almaty-region/balkhash")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Enbekshi}
                title={"Енбекшиказахский район"}
                territory={"37,4 тыс.кв.км."}
                population={"29 517 человек на 1 июля 2022 года "}
                county={"15"}
                settlements={"28"}
                text={"Специализация района – аграрный район, а также специализируется на переработке сельскохозяйственной продукции."}
                action={() => navigate("/almaty-region/enbekshikazakh")}
              />
            </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={Karasay}
                  title={"Карасайский район"}
                  territory={"2,0 тыс.кв.км."}
                  population={"299 612 человек на 1 июля 2022 года"}
                  county={"10"}
                  settlements={"47 (46 СНП и 1 город)"}
                  text={"Специализация района – аграрно-промышленной направленности."}
                  action={() => navigate("/almaty-region/karasay")}
                />
              </div>
            </div>
           <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                  img={Kegen}
                  title={"Кегенский район"}
                  territory={"7,1 тыс.кв.км."}
                  population={"29 885 человек   на 1 июля 2022 года"}
                  county={"12"}
                  settlements={"34"}
                  text={"Специализация района – аграрно-промышленной  направленности."}
                  action={() => navigate("/almaty-region/kegen")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Raymbek}
                title={"Райымбекский район"}
                territory={"37,4 тыс.кв.км."}
                population={"29 517 человек на 1 июля 2022 года"}
                county={"15"}
                settlements={"28"}
                text={"Специализация района – аграрный район, а также специализируется на переработке сельскохозяйственной продукции."}
                action={() => navigate("/almaty-region/raiymbek")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                  img={Talgar}
                  title={"Талгарский район"}
                  territory={"3,6 тыс.кв.км."}
                  population={"218 513 человек"}
                  county={"10"}
                  settlements={"48 (47 СНП и 1 город)"}
                  text={"Специализация района – аграрный район, а также специализируется на переработке сельскохозяйственной продукции."}
                  action={() => navigate("/almaty-region/talgar")}
              />
            </div>
           </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                  img={Konayev}
                  title={"Акимат города Қонаев"}
                  territory={"3,7 тыс.кв.км."}
                  population={"61 126 человек   на 1 июля 2022 года"}
                  county={"2"}
                  settlements={"12"}
                  text={"Специализация города и региона  г.Қонаев – спутник Алматы с развитой промышленностью, туристическим и оздоровительным комплексом, игорным бизнесом, водным и железнодорожным транспортом и широкой сферой услуг"}
                  action={() => navigate("/almaty-region/konayev")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                  img={Uygur}
                  title={"Уйгурский район"}
                  territory={"8,7 тыс.кв.км."}
                  population={"62 223 человек   на 1 июля 2022 года"}
                  county={"14"}
                  settlements={"25"}
                  text={"Специализация района – аграрный район, а также специализирующийся на переработке сельхозпродукции."}
                  action={() => navigate("/almaty-region/uygur")}
              />
            </div>
          </div>
        </div>
      </div>
      {/*<div className="lightBg">*/}
      {/*  <div className="container">*/}
      {/*    <Advertising className="flexSpaceCenter">*/}
      {/*      <AddLeft>*/}
      {/*        <AddLeftInner>*/}
      {/*          <ImgWrapper className="flexCenter">*/}
      {/*            <img className="radius8" src={AddImage2} alt="add" />*/}
      {/*          </ImgWrapper>*/}
      {/*        </AddLeftInner>*/}
      {/*      </AddLeft>*/}
      {/*      <AddRight>*/}
      {/*        <h4 className="font15 semiBold">A few words about company</h4>*/}
      {/*        <h2 className="font40 extraBold">A Study of Creativity</h2>*/}
      {/*        <p className="font12">*/}
      {/*          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed*/}
      {/*          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.*/}
      {/*        </p>*/}
      {/*        <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>*/}
      {/*          <div style={{ width: "190px" }}>*/}
      {/*            <FullButton title="Get Started" action={() => alert("clicked")}  border={false}/>*/}
      {/*          </div>*/}
      {/*          <div style={{ width: "190px", marginLeft: "15px" }}>*/}
      {/*            <FullButton title="Contact Us" action={() => alert("clicked")} border={true} />*/}
      {/*          </div>*/}
      {/*        </ButtonsRow>*/}
      {/*      </AddRight>*/}
      {/*    </Advertising>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
