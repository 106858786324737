import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';

import { useSelector } from "react-redux";
import { State } from '../../../redux/store';
import { useTranslation } from 'react-i18next';
import {i18translateType} from "../../../i18n";
import Sidebar from "../SidebarV2";
import Backdrop from "../../../components/Elements/Backdrop";
import LogoIcon from "../../../assets/svg/Logo";
import BurgerIcon from "../../../assets/svg/BurgerIcon";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import Link from '@mui/material/Link';
import {useLocation} from "react-router-dom";
import {ProfileMenu} from "../../../components/Menu/ProfileMenu";

const Topbar = ({
                    themeMode,
                    themeToggle,
                    setThemePalette,
                    onSidebarOpen,
                    paletteType,
                    removeLandingLinks
                }: {
    themeMode: "light" | "dark";
    themeToggle: () => void;
    setThemePalette: (type?: string) => void;
    onSidebarOpen: () => void;
    paletteType: string;
    removeLandingLinks: boolean
}) => {
    const { t }: i18translateType = useTranslation();
    const [y, setY] = useState(window.scrollY);
    const [sidebarOpen, toggleSidebar] = useState(false);
    const theme = useTheme()
    let location = useLocation();

    const state = useSelector((state: State) => state);

    useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
            window.removeEventListener("scroll", () => setY(window.scrollY));
        };
    }, [y]);


    function AuthButton() {
        if(state.auth.user) {
           return <></>
        }
        return <Link
            style={{ padding: "10px 15px" }}
            underline="none"
            component="a"
            href="/login"
            color="textPrimary"
        >
            Вход в систему
        </Link>
    }

    function SettingsButton() {
        if(state.auth.user) {
            return  <ProfileMenu themeMode={themeMode} />
        }
        return <></>
    }

    function MainLinks (props : { removeLandingLinks: boolean }) {
        if(props.removeLandingLinks) {
            return <></>
        }
        return <UlWrapper className="flexNullCenter">
                    <li className="semiBold font15 pointer">
                        <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                            Главная
                        </ScrollLink>
                      </li>
                    {/*<li className="semiBold font15 pointer">*/}
                    {/*    <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>*/}
                    {/*        Services*/}
                    {/*    </ScrollLink>*/}
                    {/*</li>*/}
                    <li className="semiBold font15 pointer">
                        <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                            Инфо. о районах
                        </ScrollLink>
                    </li>
                    {/*<li className="semiBold font15 pointer">*/}
                    {/*    <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>*/}
                    {/*        Blog*/}
                    {/*    </ScrollLink>*/}
                    {/*</li>*/}
                    {/*<li className="semiBold font15 pointer">*/}
                    {/*    <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>*/}
                    {/*        Pricing*/}
                    {/*    </ScrollLink>*/}
                    {/*</li>*/}
                    {/*<li className="semiBold font15 pointer">*/}
                    {/*    <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>*/}
                    {/*        Обратная связь*/}
                    {/*    </ScrollLink>*/}
                    {/*</li>*/}
                </UlWrapper>
    }

    return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
            <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
                <NavInner className="container flexSpaceCenter">

                    <Link
                        underline="none"
                        component="a"
                        href="/"
                        color="textPrimary"
                        className="pointer flexNullCenter">
                        <LogoIcon />
                        <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                                Planner
                        </h1>
                    </Link>
                    <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
                        <BurgerIcon />
                    </BurderWrapper>
                    <MainLinks removeLandingLinks={removeLandingLinks}/>
                    <UlWrapperRight className="flexNullCenter">
                        <li className="semiBold font15 pointer">
                            <AuthButton/>
                        </li>
                        <li className="semiBold font15 pointer flexCenter">
                            <SettingsButton/>
                        </li>
                    </UlWrapperRight>
                </NavInner>
            </Wrapper>
        </>
    );
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func,
    themeToggle: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
    setThemePalette: PropTypes.func.isRequired,
    paletteType: PropTypes.string.isRequired,
    removeLandingLinks: PropTypes.bool
};

const Wrapper = styled.nav`
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

export default Topbar
